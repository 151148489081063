import React from 'react'
import JssProvider from 'react-jss/lib/JssProvider';
import createGenerateClassName from '@material-ui/core/styles/createGenerateClassName';

import { ThemeProvider } from 'emotion-theming';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import emotionTheme from '../theme/emotionTheme'
import muiTheme from '../theme/muiTheme'

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true
});

export default () => WrappedComponent => props => (
  <JssProvider generateClassName={generateClassName}>
    <ThemeProvider theme={emotionTheme}>
      <MuiThemeProvider theme={muiTheme}>
        <WrappedComponent {...props} />
      </MuiThemeProvider>
    </ThemeProvider>
  </JssProvider>
)

import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Block, Image, Text, GatsbyImage } from 'components/atoms';
import styled from '@emotion/styled';
import logo from 'assets/logo.svg';

import { Link } from 'gatsby';
import { Link as Scroll } from 'react-scroll';

const pumpkinHead = 'assets/pumpkin-head.png';
const FacebookLogo = 'assets/Facebook.png';
const lineLogo = 'assets/line-logo.png';
const muzeLogo = 'assets/muze-logo.png';

const FooterDiv = styled('div')({
  marginTop: 30,
  '.header': {
    color: '#767677',
    fontWeight: 'bold',
    fontSize: 19,
    marginBottom: 14
  },
  '.linkText': {
    fontSize: 15,
    color: '#767677',
    marginBottom: 10,
    cursor: 'pointer'
  },

  '.topicBox': {
    display: 'flex'
  },
  '.highlightText': {
    fontWeight: 'bold',
    color: '#767677'
  },
  '.contactText': {
    fontSize: 15,
    color: '#767677',
    marginBottom: 10
  }
});

const MerChantFooter = () => {
  const [downloadSectionExist, setDownloadSectionExist] = React.useState(null);

  useEffect(() => {
    setDownloadSectionExist(document.getElementById('downloadSection'));
  }, []);

  return (
    <FooterDiv>
      <Block
        p={30}
        pb={0}
        maxWidth={['initial', 700, 960, 1200]}
        mx={'auto'}
        position={'relative'}>
        <GatsbyImage
          src={pumpkinHead}
          position={'absolute'}
          left={['44%', '46%', '47%']}
          top={[-60, -80, -80]}
          width={[40, 63, 79]}
          height={[47, 74, 94]}
          zIndex={5}
        />
        <Grid container>
          <Grid item xs={12} sm={6} className={'topicBox'}>
            <Block mr={[15, 100]}>
              <Text className={'header'}>นักสะสม</Text>
              <Link to="/#privilege" style={{ textDecoration: 'none' }}>
                <Text className={'linkText'}> PUMPKIN สำหรับนักสะสม</Text>
              </Link>
              <Link to="/login" style={{ textDecoration: 'none' }}>
                <Text className={'linkText'}>สมัครสมาชิก/เข้าสู่ระบบ</Text>
              </Link>
              {downloadSectionExist ? (
                <Scroll to="downloadSection" smooth={true} duration={500}>
                  <Text className={'linkText'}>ดาวน์โหลด</Text>
                </Scroll>
              ) : (
                <Link to="#downloadSection" style={{ textDecoration: 'none' }}>
                  <Text className={'linkText'}>ดาวน์โหลด</Text>
                </Link>
              )}
              <Link
                to="/term-and-condition-app"
                style={{ textDecoration: 'none' }}>
                <Text className={'linkText'}>ข้อตกลงและเงื่อนไข</Text>
              </Link>
            </Block>
            <Block>
              <Text className={'header'}>ร้านค้า</Text>
              <Link to="/merchant" style={{ textDecoration: 'none' }}>
                <Text className={'linkText'}>PUMPKIN คืออะไร?</Text>
              </Link>
              {/*<Link to="/blog" style={{ textDecoration: 'none' }}>*/}
              {/*  <Text className={'linkText'}>ข้อมูลสำหรับร้านค้า</Text>*/}
              {/*</Link>*/}
              <a
                href={`${process.env.DASHBOARD_HOST}/register`}
                style={{ textDecoration: 'none' }}>
                <Text className={'linkText'}>เข้าร่วมกับเรา</Text>
              </a>
              <a
                href={`${process.env.DASHBOARD_HOST}/login`}
                style={{ textDecoration: 'none' }}>
                <Text className={'linkText'}>เข้าสู่แดชบอร์ด</Text>
              </a>
            </Block>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Block
              display={'flex'}
              justifyContent={[
                'center',
                'flex-start',
                'flex-start',
                'flex-end'
              ]}
              flexDirection={['column', 'row']}>
              <Block maxWidth={['100%', '50%']} mt={[30, 0, 0, 0]}>
                <Text className={'header'}>Muze Innovation</Text>
                <Text className={'contactText'}>
                  <span className={'highlightText'}>Address</span>: 89 AIA
                  Capital Center, Floor 25th Ratchadapisek Rd., Din Daeng,
                  Bangkok 10400
                </Text>
                <Text className={'contactText'}>
                  <span className={'highlightText'}>Tel</span>: +66 2 248 4695
                </Text>
                <Text className={'contactText'}>
                  <span className={'highlightText'}>Email</span>:
                  pumpkinapp@muze.co.th
                </Text>
              </Block>
              <Block
                maxWidth={['100%', '50%']}
                display={'flex'}
                flexDirection={['row', 'column']}
                justifyContent={'space-between'}
                mt={[30, 0, 0, 0]}>
                <Image
                  mt={'4px'}
                  src={logo}
                  objectFit={'contain'}
                  width={['50%', '100%']}
                />
                <Block>
                  <Text
                    fontSize={14}
                    fontWeight={'bold'}
                    color={'#767677'}
                    textAlign={'right'}
                    mb={10}>
                    ติดตามเราได้ที่
                  </Text>
                  <Block display={'flex'} justifyContent={'flex-end'}>
                    <a
                      href={'https://line.me/R/ti/p/%40joj8029p'}
                      target="_blank">
                      <GatsbyImage
                        src={lineLogo}
                        width={43}
                        height={43}
                        mr={16}
                      />
                    </a>
                    <a
                      href={'https://facebook.com/pumpkinapp.co'}
                      target="_blank">
                      <GatsbyImage src={FacebookLogo} width={43} height={43} />
                    </a>
                  </Block>
                </Block>
              </Block>
            </Block>
          </Grid>
        </Grid>
      </Block>
      <Block
        mt={[20, 25, 35]}
        pt={25}
        pr={30}
        pl={30}
        pb={25}
        borderTop={'1px solid #eeeeee'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        flexDirection={['column', 'row']}>
        <Block display={'flex'}>
          <Text
            fontFamily={'thai'}
            fontSize={13}
            fontWeight={'bold'}
            color={'#767677'}
            mr={['6.5px', 15]}>
            Powered By
          </Text>
          <GatsbyImage src={muzeLogo} width={30} />
        </Block>
        <Text
          textAlign={['right']}
          fontSize={'10px'}
          fontWeight={'bold'}
          color={'#767677'}>
          Copyright © 2018 Pumpkinapp. All rights reserved.
        </Text>
      </Block>
    </FooterDiv>
  );
};

export default MerChantFooter;

import React from 'react';
import { Block, Text } from 'components/atoms';
import { PrivilegeCard } from 'components/molecules';

// REDUX
import { connect } from 'react-redux';
import {
  selectGetPrivilegesStatus,
  selectPrivileges
} from 'modules/privilege/selectors';
import { getPrivileges } from 'modules/privilege/actions';
import { bindActionCreators } from 'redux';

class PrivilegeSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { privileges } = this.props;
    if (!privileges || !privileges.length) {
      this.props.getPrivileges();
    }
  }

  checkMt = index => {
    if (index === 0) {
      return [0, 0, 0, 70];
    }
    if (index === 2) {
      return [0, 0, 0, 50];
    }
    return 0;
  };

  render() {
    const { privileges } = this.props;
    return (
      <Block id='privilegeSection' maxWidth={['initial', 700, 960, 1200]} mx={'auto'} py={70}>
        <Text
          fontSize={36}
          textAlign={'center'}
          fontWeight={'bold'}
          mb={50} 
          color={'#893f18'}>
          PRIVILEGE
        </Text>
        <Block.Flex
          justifyContent={'center'}
          alignItems={['center', 'center', 'flex-start']}
          flexDirection={['column', 'column', 'row']}
          flexWrap={'wrap'}>
          {privileges.map((privilege, index) => (
            <PrivilegeCard
              storeTitle={privilege.name}
              storeType={privilege.type}
              logoPath={privilege.logo}
              imagePath={privilege.reward.image}
              privilegeDescription={privilege.reward.title}
              stampQuantity={privilege.reward.point}
              mt={this.checkMt(index)}
            />
          ))}
        </Block.Flex>
      </Block>
    );
  }
}
export default connect(
  state => ({
    getPrivilegesStatus: selectGetPrivilegesStatus(state),
    privileges: selectPrivileges(state)
  }),
  dispatch =>
    bindActionCreators(
      {
        getPrivileges
      },
      dispatch
    )
)(PrivilegeSection);

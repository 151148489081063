import React from 'react';
import {
  Block,
  Text,
  GatsbyImage,
  GatsbyImageBackground
} from 'components/atoms';
import { css } from '@emotion/core';

const PumpkinBoyDownload = 'assets/pumpkin-boy-download.png';
const IphoneDownload = 'assets/iphone-download2.png';
const appstore = 'assets/app-store.png';
const googleplay = 'assets/google-play.png';
const userInDownloadSection = 'assets/user-in-download-section.png';
const DownloadBg = 'assets/download-Bg.png';

let highlightText = css`
  display: inline-block !important;
`;

const DownloadSection = () => {
  let appStoreLink = 'https://apps.apple.com/th/app/pumpkin/id1392855143?l=th';
  let playStoreLink =
    'https://play.google.com/store/apps/details?id=com.mi.pumpkin';
  const userAgent = typeof window !== 'undefined' && window.navigator.userAgent;
  const platform = typeof window !== 'undefined' && window.navigator.platform;
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

  if (iosPlatforms.indexOf(platform) !== -1) {
    appStoreLink = 'itms://itunes.apple.com/th/app/pumpkin/id1392855143?mt=8';
  } else if (/Android/.test(userAgent)) {
    playStoreLink = 'market://details?id=com.mi.pumpkin';
  }

  return (
    <Block id={'downloadSection'} pb={[60, 0]} background={'#c5edff'}>
      <GatsbyImageBackground
        src={DownloadBg}
        backgroundStyle={{
          backgroundSize: 'cover',
          backgroundPosition: 'center bottom',
          backgroundRepeat: 'no-repeat'
        }}>
        <Block pt={60} pb={[0, 60]}>
          <Block.Flex
            maxWidth={['inherit', 700, 960, 1200]}
            height={'100%'}
            mx={'auto'}
            position={'relative'}
            justifyContent={'center'}
            alignItems={'flex-end'}>
            <GatsbyImage
              src={PumpkinBoyDownload}
              width={[120, 140, 190]}
              mr={[-35, -35, -45]}
              imageStyle={{ objectFit: 'contain' }}
              zIndex={2}
            />
            <GatsbyImage
              src={IphoneDownload}
              width={[150, 170, 270]}
              imageStyle={{ objectFit: 'contain' }}
              zIndex={1}
            />
            <Block
              position={'relative'}
              top={[-100, -100, -144]}
              pl={[0, 15, 15, 30]}
              display={['none', 'block']}>
              <Text fontSize={50} fontWeight={'bold'}>
                ไม่ต้องพก{' '}
                <Text
                  css={highlightText}
                  color={'primary'}
                  fontSize={50}
                  fontWeight={'bold'}>
                  บัตรสะสม
                </Text>
              </Text>
              <Text fontSize={36}>ให้ล้นกระเป๋าอีกต่อไป</Text>
              <Block display={'flex'} flexDirection={['row', 'column']}>
                <Block width={220} mt={25}>
                  <a
                    href={playStoreLink}
                    target="_blank"
                    rel="noopener noreferrer">
                    <GatsbyImage src={googleplay} />
                  </a>
                </Block>
                <Block width={220} mt={15}>
                  <a
                    href={appStoreLink}
                    target="_blank"
                    rel="noopener noreferrer">
                    <GatsbyImage src={appstore} />
                  </a>
                </Block>
              </Block>
            </Block>
            <GatsbyImage
              src={userInDownloadSection}
              width={[88, 100, 140]}
              ml={[-3, -90, -70]}
              zIndex={2}
              objectFit={'contain'}
            />
          </Block.Flex>
        </Block>
      </GatsbyImageBackground>
      <Block
        display={['flex', 'none']}
        flexDirection={'column'}
        alignItems={'center'}
        py={20}>
        <Text fontSize={36} fontWeight={'bold'}>
          ไม่ต้องพก{' '}
          <Text
            css={highlightText}
            color={'primary'}
            fontSize={36}
            fontWeight={'bold'}>
            บัตรสะสม
          </Text>
        </Text>
        <Text fontSize={25}>ให้ล้นกระเป๋าอีกต่อไป</Text>
        <Block display={'flex'} mt={15}>
          <Block width={120} mr={10}>
            <a href={playStoreLink} target="_blank" rel="noopener noreferrer">
              <GatsbyImage src={googleplay} />
            </a>
          </Block>
          <Block width={120}>
            <a href={appStoreLink} target="_blank" rel="noopener noreferrer">
              <GatsbyImage src={appstore} />
            </a>
          </Block>
        </Block>
      </Block>
    </Block>
  );
};

export default DownloadSection;

import AutoClose from './AutoClose'
import DeleteModal from './DeleteModal'
import Modal from './Modal'

export {
  DeleteModal
}

Modal.Delete = DeleteModal
Modal.AutoClose = AutoClose

export default Modal
import React from 'react';
import { Image, Text, Block, Button } from 'components/atoms';
import card from 'assets/coupon.png';
import redeemedCard from 'assets/redeemed-coupon.png';
import * as moment from 'moment';

const CouponsCard = ({ expiredDate, image, title }) => {
  return (
    <Block.Flex
      display={'flex'}
      flexDirection={'column'}
      backgroundImage={redeemedCard}
      width={['95%', 364]}
      backgroundSize={['cover', 'contain']}
      backgroundRepeat={'round'}
      mt={[20]}
      mb={[20]}
      ml={[0, 20]}
      ml={[0, 20]}
      style={{
        // boxShadow:
        //   '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        borderRadius: '3%'
      }}>
      <Block display={'flex'} flexDirection={'row'}>
        <Image
          src={image}
          objectFit={'cover'}
          height={[50, 75]}
          width={['20%', 75]}
          m={['5.5% 3% 3% 9%','2% 3% 3% 9%']}
        />
        <Block
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          mt={2}
          mb={2}>
          <Block
            css={{
              WebkitLineClamp: '1',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden'
            }}>
            <Text
              fontWeight={'bold'}
              style={{ fontSize: 20, wordWrap: 'break-word' }}>
              {title}
            </Text>
          </Block>
          <Text
            color={'grey'}
            style={{ wordWrap: 'break-word' }}
            fontSize={[13, 16]}>
            Available until :
            {moment(expiredDate).format('DD MMM YYYY')}
          </Text>
        </Block>
      </Block>
      <Block display={'flex'} justifyContent={'center'} mt={'11px'}>
        {moment().isAfter(moment(expiredDate)) ? (
          <Text fontWeight={'bold'} color="#d72300">
            Expried
          </Text>
        ) : (
          <Text fontWeight={'bold'} color="#3c7600">
            Redeemed
          </Text>
        )}
      </Block>
    </Block.Flex>
  );
};

export default CouponsCard;

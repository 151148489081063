import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Image, Text } from 'components/atoms';
import { ClassNames } from '@emotion/core';

const StampCard = styled(
  ({ cover, logo, name, description, point, textColor, className }) => {
    return (
      <ClassNames>
        {({ cx }) => (
          <div className={cx('stamp-card', className)}>
            <Image className={'image'} src={cover} />
            <div className={'gradient'} />
            <div className={'info'}>
              <Image className={'logo'} src={logo} />
              <div className={'content'}>
                <Text
                  className={'name'}
                  color={textColor}
                  fontSize={4}
                  fontWeight={500}>
                  {name.toUpperCase()}
                </Text>
                <Text
                  className={'description'}
                  color={'grey'}
                  fontSize={3}
                  fontWeight={500}>
                  {description}
                </Text>
              </div>
              <div className={'divider'} />
              <div className={'point'}>
                <Text
                  className={'value'}
                  color={textColor}
                  fontSize={6}
                  fontWeight={700}>
                  {point}
                </Text>
                <Text
                  className={'pump'}
                  color={'grey'}
                  fontSize={2}
                  fontWeight={500}>
                  PUMP
                </Text>
              </div>
            </div>
          </div>
        )}
      </ClassNames>
    );
  }
)(({ theme, gradientColor }) => ({
  width: 425,
  '@media only screen and (max-width: 450px)': {
    width: 'auto'
  },
  borderRadius: 12,
  boxShadow: theme.get('globalShadows.lightShadow'),
  overflow: 'hidden',
  height: 225,
  minWidth: 286, // for iPhone5s = 320 - (17*2) paddingLeft&Right
  position: 'relative',
  cursor: 'pointer',
  '.image': {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    transition: '0.4s'
  },
  '.gradient': {
    height: '75%',
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    background: `linear-gradient(transparent,${gradientColor})`
  },
  '.info': {
    position: 'absolute',
    padding: '16px 20px',
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    '.logo': {
      width: 50,
      height: 50,
      borderRadius: 12
    },
    '.content': {
      minWidth: 0,
      flex: '1 1 auto',
      paddingLeft: 16,
      paddingRight: 8,
      '.name': theme.get('thaiFont'),
      '.description': {
        marginTop: -7,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        ...theme.get('thaiFont')
      }
    }
  },
  '.divider': {
    width: 1,
    height: 48,
    flexShrink: 0,
    backgroundColor: theme.get('colors.darkGrey')
  },
  '.point': {
    paddingLeft: 16,
    '.value': { ...theme.get('thaiFont'), marginTop: -10, textAlign: 'right' },
    '.pump': { ...theme.get('thaiFont'), marginTop: -20, textAlign: 'right' }
  }
}));

StampCard.propTypes = {
  cover: PropTypes.string,
  logo: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  textColor: PropTypes.string,
  point: PropTypes.number,
  gradientColor: PropTypes.string,
  className: PropTypes.string
};

export default StampCard;

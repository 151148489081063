import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Block } from 'components/atoms';
import omit from 'lodash/omit';

const GatsbyImage = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.src);
      });
      if (!image) {
        return null;
      }
      const imageFluid = image.node.childImageSharp.fluid;
      return (
        <Block {...omit(props, ['src', 'imageStyle'])}>
          <Img
            alt={props.alt}
            fluid={imageFluid}
            style={{ width: '100%', height: '100%' }}
            imgStyle={{ ...props.imageStyle }}
          />
        </Block>
      );
    }}
  />
);

export default GatsbyImage;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ClassNames } from '@emotion/core';
import { Image, Text, Block } from 'components/atoms';

const WorkingStepBox = styled(({ imagePath, stepTitle, className }) => (
  <ClassNames>
    {({ cx }) => (
      <Block className={cx('working-step-box', className)} pr={40} pl={40}>
        <Image.Dynamic
          src={imagePath}
          className={'previewImage'}
          width={160}
          height={160}
          objectFit={'contain'}
        />
        <Text
          color={'black'}
          fontSize={24}
          fontWeight={'bold'}
          mt={'8px'}
          fontFamily={'thai'}>
          {stepTitle}
        </Text>
      </Block>
    )}
  </ClassNames>
))(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  '.number-box': {
    backgroundColor: '#fe9526',
    borderRadius: 50
  }
}));

WorkingStepBox.propTypes = {
  imagePath: PropTypes.string,
  stepTitle: PropTypes.string
};
WorkingStepBox.propTypes = {
  imagePath: '',
  stepTitle: ''
};
export default WorkingStepBox;

import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import styled from '@emotion/styled';
import IconButton from '@material-ui/core/IconButton';

import ArrowBackIos from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIosOutlined';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const StyledIconButton = styled(IconButton)({
  '&.slider__arrow': {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 100
  },
  '&.slider__arrow--left': {
    left: 16
  },
  '&.slider__arrow--right': {
    right: 16
  }
});

const Arrow = ({ icon, direction, onClick }) => (
  <StyledIconButton
    className={`slider__arrow slider__arrow--${direction}`}
    onClick={onClick}>
    {icon}
  </StyledIconButton>
);

const Slick = ({ settings, children }) => {
  const defaultSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide:0,
    nextArrow: <Arrow direction={'right'} icon={<ArrowForwardIos />} />,
    prevArrow: <Arrow direction={'left'} icon={<ArrowBackIos />} />
  };
  return (
    <Slider {...defaultSettings} {...settings}>
      {children}
    </Slider>
  );
};

Slick.propTypes = {
  settings: PropTypes.shape({})
};
Slick.defaultProps = {
  settings: {}
};

export default Slick;

import toArray from 'lodash/toArray';
import trim from 'lodash/trim';
import get from 'lodash/get'
import { styles } from 'styled-system';

export const decryptQRCode = path => {
  if (get(path, 'goto')) {
    return {goto : get(path,'goto',null)}
  }
  else {
    if (path) {
      const items = path.split(';');
      let cashierId, brandId, surveyId;
      if (items.length >= 2) {
        cashierId = trim(items[0].split('cashier:')[1]);
        surveyId = trim(items[1].split('surveyId:')[1]);
        brandId =
          trim(items[1].split('brand:')[1]) || trim(items[0].split('brand:')[1]);
      }
      return {
        cashierId,
        brandId,
        surveyId
      };
    } else {
      return { cashierId: '', brandId: '' };
    }
  }
};

export const calculateEmptyRows = (dataLength, rowsPerPage, page) =>
  rowsPerPage - Math.min(rowsPerPage, dataLength - page * rowsPerPage);

export const getSorting = (order, orderBy) =>
  order === 'desc'
    ? (a, b) => b[orderBy] - a[orderBy]
    : (a, b) => a[orderBy] - b[orderBy];

export const reduceStyles = className => {
  const styleList = toArray(styles);
  return styleList.map(func => {
    return props => ({
      [className]: typeof func === 'function' ? func(props) : {}
    });
  });
};

export const displayError = code => {
  switch (code) {
    case '':
      return '';
    case 'auth/email-not-found':
      return 'โปรดอัพเดทอีเมล์ในไลน์ก่อนเข้าสู่ระบบ';
    case 'auth/invalid-email':
      return 'โปรดกรอกอีเมล์ให้ถูกต้อง';
    case 'auth/invalid-password':
      return 'รหัสผ่านต้องมีจำนวน 6 ตัวขึ้นไป (a-z, 0-9)';
    case 'auth/email-already-exists':
      return 'อีเมล์นี้ถูกใช้ไปแล้ว โปรดระบุใหม่';
    case 'auth/wrong-password':
      return 'รหัสผ่านไม่ถูกต้อง';
    case 'auth/user-not-found':
      return 'ไม่พบบัญชีนี้ โปรดลองใหม่อีกครั้ง';
    case 'password-mismatch':
      return 'รหัสผ่านต้องตรงกัน';
    default:
      return 'เกิดข้อผิดพลาด ไม่สามารถเข้าสู่ระบบได้';
  }
};

export const checkGender = gender => {
  if (gender === 'male') return 'ชาย';
  else if (gender === 'female') return 'หญิง';
  else return '-';
};

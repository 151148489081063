import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { Text, Block } from 'components/atoms'

const styles = (theme) => {
  return {
    paper: theme.paper,
    header: theme.paper.header
  }
}

const PaperHeader = withStyles(styles)(
  ({ classes, className, leftItem, rightItem, }) => {
    return (
      <Block.Middle p={'12px 12px 20px 20px'} justifyContent={'space-between'}>
        {typeof leftItem === 'string'
          ? <Text variant="title">{leftItem}</Text>
          : leftItem}
        {rightItem}
      </Block.Middle>
    )
  }
)

export default PaperHeader

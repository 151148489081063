import React from 'react'
import PropTypes from 'prop-types'
import isObject from 'lodash/isObject'
import noResult from 'assets/no_result.jpg'
import { Image, Block, Text } from 'components/atoms'

const NoResult = ({ text, image, ...rest }) => {
  return (
    <Block.MiddleCenter height={'100%'} {...rest}>
      <div>
        <Image
          src={image || noResult}
          style={{
            maxWidth: 300,
            margin: 'auto',
            maxHeight: 150,
          }}
        />
        {isObject(text) ? (
          text
        ) : (
          <Text
            fontSize={4}
            fontWeight={'ultraLight'}
            color={'lightGrey'}
            textAlign={'center'}
            letterSpacing={'1px'}
            mt={20}
          >
            {text}
          </Text>
        )}
      </div>
    </Block.MiddleCenter>
  )
}

NoResult.propTypes = {
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  image: PropTypes.string,
}
NoResult.defaultProps = {
  text: 'ว่างเปล่า',
}

export default NoResult

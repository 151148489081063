import React, { useState, useEffect } from 'react';
import firebase from 'appFirebase/config';
import { Block, Text, Button } from 'components/atoms';
import { Modal } from 'components/molecules';
import withTheme from 'hocs/withTheme';
import { PhoneNumberForm, OtpForm } from 'components/forms/FormItems';
import get from 'lodash/get';

// CloudApi
import CloudApi from 'api/cloudApi';

// REDUX
import { connect } from 'react-redux';
import { getProfile } from 'modules/auth/actions';
import { bindActionCreators } from 'redux';

const PhoneModal = ({ open, onClose, profile, getProfile, inMyAccount }) => {
  const [modalState, setModalState] = useState('init');
  const [phoneNumber, setNumber] = useState(null);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [sendOtpPending, setSendOtpPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [otp, setOtp] = useState(null);
  const [countDownKey, setCountDownKey] = useState(null);
  const [unLinkLoading, setUnLinkLoading] = useState(false);

  useEffect(() => {
    if (inMyAccount) {
      if (get(profile, 'phoneNumber')) {
        setModalState('warning');
      } else {
        setModalState('addPhoneNumber');
      }
    }
  }, []);

  const handleSubmit = ({ confirmationResult, phoneNumber }) => {
    console.log('phoneNumber', phoneNumber);
    setModalState('addOtp');
    setNumber(phoneNumber);
    setConfirmationResult(confirmationResult);
  };

  const onChangeOtp = otp => {
    setOtp(otp);
  };

  const handleOTP = otpData => {
    setSendOtpPending(true);
    return confirmationResult
      .confirm(otpData)
      .then(() => {
        return firebase
          .auth()
          .currentUser.unlink('phone')
          .then(() =>
            CloudApi.updatePhoneNumber({
              phoneNumber,
              phoneAlreadyUse: false
            })
          )
          .then(() => {
            setSendOtpPending(false);
            setOtp(null);
            getProfile();
            onClose();
          });
      })
      .catch(err => {
        if (err.code === 'auth/credential-already-in-use') {
          return CloudApi.updatePhoneNumber({
            phoneNumber: phoneNumber,
            phoneAlreadyUse: true
          }).then(() => {
            setSendOtpPending(false);
            getProfile();
            setOtp(null);
            return onClose();
          });
        }
        if (err.code === 'auth/invalid-verification-code') {
          setErrorMessage('OTP ที่กรอกไม่ถูกต้องกรุณากรอกใหม่อีกครั้ง');
          setSendOtpPending(false);
        } else {
          setErrorMessage('ไม่สามารถทำรายการได้ในขณะนี้กรุณาลองใหม่อีกครั้ง');
          setSendOtpPending(false);
        }
      });
  };

  const handleResend = () => {
    firebase.auth().languageCode = 'en';
    window.recaptchaVerifier.render().then(function(widgetId) {
      window.grecaptcha.reset(widgetId);
    });
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'resend-button',
      {
        size: 'invisible',
        callback: function(response) {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log(response);
        }
      }
    );
    var appVerifier = window.recaptchaVerifier;
    return (
      firebase
        .auth()
        .currentUser.linkWithPhoneNumber(phoneNumber, appVerifier)
        // .then(data => console.log('data', data));
        .then(confirmationResult => {
          setConfirmationResult(confirmationResult);
          setCountDownKey(Date.now());
        })
        .catch(error => {
          if (error.code === 'auth/too-many-requests') {
            setErrorMessage(
              'We have blocked all requests from this device due to unusual activity. Try again later.'
            );
          } else setErrorMessage('Something wrong,Please try later');
        })
    );
  };

  return (
    <Modal
      open={open}
      content={
        <Block width={['auto', 400]}>
          {modalState === 'init' && (
            <Block>
              <Text
                fontWeight={'bold'}
                fontSize={[20, 23]}
                textAlign={'center'}
                mb={'10px'}>
                สะสมแต้มง่ายๆ ผ่านเบอร์ของคุณ
              </Text>
              <Text mb={'5px'}>
                - เพิ่มเบอร์โทรศัพท์เพื่อทำการสะสมแต้มผ่านเบอร์โทรศัพท์
              </Text>
              <Text>- สามารถบอกเบอร์กับพนักงานเพื่อทำการสะสมแต้มได้</Text>
              <Block
                display={'flex'}
                flexDirection={['column', 'row']}
                justifyContent={'space-between'}
                mt={[10, 15]}>
                <Button.Contain
                  color={'primary'}
                  onClick={() => setModalState('addPhoneNumber')}>
                  เพิ่มเบอร์โทรศัพท์
                </Button.Contain>
                <Button.OutLined
                  color={'primary'}
                  mt={['20px', 0]}
                  onClick={() => onClose()}>
                  เพิ่มภายหลัง
                </Button.OutLined>
              </Block>
            </Block>
          )}
          {modalState === 'addPhoneNumber' && (
            <PhoneNumberForm profile={profile} handleSubmit={handleSubmit} />
          )}
          {modalState === 'addOtp' && (
            <OtpForm
              otp={otp}
              errorMessage={errorMessage}
              sendOtpPending={sendOtpPending}
              onChange={onChangeOtp}
              handleOTP={handleOTP}
              handleResend={handleResend}
              countDownKey={countDownKey}
              setCountDownKey={setCountDownKey}
            />
          )}
          {modalState === 'warning' && (
            <Block textAlign={'center'}>
              <Text fontWeight={'bold'} fontSize={20}>
                คำเตือน
              </Text>
              <Text mt={20}>
                คุณได้ทำการผูกเบอร์โทรศัพท์ {get(profile, 'phoneNumber')}{' '}
                ไว้อยู่แล้ว คุณต้องการทีจะยกเลิกเบอร์นี้ใช่หรือไม่
              </Text>
              <Block display={'flex'} mt={25} justifyContent={'flex-end'}>
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  onClick={() => onClose()}
                  mr={25}>
                  ยกเลิก
                </Button>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  loading={unLinkLoading}
                  onClick={() => {
                    setUnLinkLoading(true);
                    return CloudApi.unLinkPhoneNumber().then(() => {
                      getProfile();
                      setUnLinkLoading(false);
                      setModalState('addPhoneNumber');
                    });
                  }}>
                  ตกลง
                </Button>
              </Block>
            </Block>
          )}
        </Block>
      }
      onClose={() => {
        setErrorMessage(null);
        setCountDownKey(null);
        onClose();
      }}
    />
  );
};

export default connect(null, dispatch =>
  bindActionCreators(
    {
      getProfile
    },
    dispatch
  )
)(PhoneModal);

import React from 'react';
import PropTypes from 'prop-types';
// REDUX
import { connect } from 'react-redux';
import { selectSurveys } from 'modules/survey/selectors';

import { withRouter } from 'react-router';
import { css } from '@emotion/core';

import BadgeNumber from 'components/BadgeNumber';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import CreditCard from '@material-ui/icons/CreditCard';
import Explore from '@material-ui/icons/Explore';
import Notifications from '@material-ui/icons/Notifications';
import SaveAlt from '@material-ui/icons/SaveAlt';

import ROUTES from 'routes';

const navs = surveys => [
  {
    label: 'CARD',
    value: ROUTES.myCards.getPath(),
    activeIcon: <CreditCard />
  },
  {
    label: 'EXPLORE',
    value: ROUTES.explore.getPath(),
    activeIcon: <Explore />
  },
  {
    label: 'NOTI',
    value: ROUTES.notification.getPath(),
    activeIcon: (
      <React.Fragment>
        <BadgeNumber surveys={surveys} />
        <Notifications />
      </React.Fragment>
    )
  },
  {
    label: 'GET APP',
    value: ROUTES.getApp.getPath(),
    activeIcon: <SaveAlt />
  }
];

const BottomNav = ({ location, history, surveys }) => {
  const handleChange = (e, value) => {
    history.push(value);
  };
  return (
    <React.Fragment>
      <div
        className={css({
          boxShadow: '0 -1px 12px 0 rgba(0,0,0,0.12)',
          position: 'fixed',
          width: '100%',
          bottom: 0,
          zIndex: 200
        })}>
        <BottomNavigation
          style={{ flexShrink: 0 }}
          value={
            location.pathname === '/survey'
              ? '/notification'
              : location.pathname
          }
          onChange={handleChange}>
          {navs(surveys).map(({ label, value, activeIcon }) => {
            return (
              <BottomNavigationAction
                key={label}
                label={label}
                value={value}
                icon={activeIcon}
              />
            );
          })}
        </BottomNavigation>
      </div>
      <div style={{ height: 60, flexShrink: 0 }} />
    </React.Fragment>
  );
};

BottomNav.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(
  connect(
    state => ({
      surveys: selectSurveys(state)
    }),
    null
  )(BottomNav)
);

import React from 'react';
import CardMembership from '@material-ui/icons/CardMembership';
import ExploreOutlined from '@material-ui/icons/ExploreOutlined';
import BusinessCenterOutlined from '@material-ui/icons/BusinessCenterOutlined';
// import ForumOutlined from '@material-ui/icons/ForumOutlined';
import { FontAwesome } from 'components/atoms';

export default [
  {
    id: 'user',
    label: 'Member',
    path: '/',
    icon: <CardMembership />
  },
  {
    id: 'merchant',
    label: 'Business',
    path: '/merchant/',
    icon: <BusinessCenterOutlined />
  },
  // {
  //   id: 'blog',
  //   label: 'Blog',
  //   path: '/blog',
  //   icon: <ForumOutlined/>
  // },
  {
    id: 'explore',
    label: 'Explore',
    path: '/shop-category/',
    icon: <ExploreOutlined />
  }
];

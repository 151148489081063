import React from 'react';
import PropTypes from 'prop-types';
import { Block } from 'components/atoms';

// ICONS
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import SwapVert from '@material-ui/icons/SwapVert';
import { ClassNames } from '@emotion/core';

const Sorter = ({ className, order, isSorting, onClick, iconProps, wrapperStyle }) => {
  const renderIcon = () => {
    if (!isSorting) {
      return (
        <SwapVert
          {...iconProps}
          onClick={onClick}
        />
      );
    } else {
      if (order === 'asc') {
        return (
          <ArrowUpward
            {...iconProps}
            onClick={onClick}
          />
        );
      } else {
        return (
          <ArrowDownward
            {...iconProps}
            onClick={onClick}
          />
        );
      }
    }
  };
  return (
    <ClassNames>
      {({ cx }) => (
        <Block className={cx('sorter', className)} {...wrapperStyle}>
          {renderIcon()}
        </Block>
      )}
    </ClassNames>
  );
};

Sorter.propTypes = {
  className: PropTypes.string,
  order: PropTypes.string.isRequired,
  isSorting: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  iconProps: PropTypes.shape({}),
  wrapperStyle: PropTypes.shape({})
};

export default Sorter;

import React from 'react';
import { Block, Image, Text, Button ,GatsbyImage} from 'components/atoms';
import { css } from '@emotion/core';
import withTheme from 'hocs/withTheme';
// import bannerTitle from 'assets/banner-title.png';
// import bannerPumpkin from 'assets/banner-pumpkin.png';
import imageService from 'helpers/imageService';

const bannerTitle = 'assets/banner-title.png';
const macDesktop = 'assets/mac-desktop.png'
const landingTown = 'assets/landing-town.png';
const phone = 'assets/phone.png'
const bannerPumpkin = 'assets/banner-pumpkin.png';

const MerchantBanner = () => {
  return (
    <Block mt={[-64, -80]} position={'relative'}>
      <Block
        position={'absolute'}
        zIndex={-1}
        top={0}
        background={'linear-gradient(to top, #FD9C1A, #FFB958)'}
        width={'100%'}>
        <Block css={{
            '@media only screen and (min-width: 768px)': {
              transform: 'translateY(60px)'
            }
          }}>
        <GatsbyImage
          src={landingTown}
          width={'100%'}
          mt={[200, 250, 180, 60]}
        />
        </Block>
        <Block
          height={[30, 60, 80, 100]}
          left={0}
          right={0}
          top={[120, 140, 220]}
          zIndex={-2}
          background={'#fd9c1a'}
          borderRadius={'0% 0% 80% 80%'}
          css={{
            transform: 'translateY(50%)'
          }}
        />
      </Block>
      <Block height={[64, 80]}/>
      <Block
        textAlign={'center'}
        mx={'auto'}
        maxWidth={['initial', 700, 960, 1200]}>
        <GatsbyImage src={bannerTitle} width={[250, 350, 450]} m={'auto'} mt={30} mb={10}/>
        <Block mb={[24, 36]}>
          <Button
            css={{
              background: 'linear-gradient(to top, #D6D6D6, #FFFFFF)'
            }}
            p={'0px 25px'}
            variant={'contained'}
            rounded
          >
            <Text fontWeight={'extraBold'} fontSize={[18, 20]}>ด้วยระบบ CRM ของ PUMPKIN</Text>
          </Button>
        </Block>
        <Block
          mb={[40, 80]}
          position={'relative'}
          display={'inline-block'}
          zIndex={2}>
          <GatsbyImage
            height={[170, 340, 390]}
            width={[280, 500, 600]}
            src={macDesktop}
          />
          <GatsbyImage
            position={'absolute'}
            width={[80, 140, 180]}
            right={[0, -56]}
            bottom={[-4, -12]}
            src={phone}
          />
          <GatsbyImage
            position={'absolute'}
            width={[70, 140, 165]}
            bottom={0}
            left={[-20, -92]}
            src={bannerPumpkin}
          />
        </Block>
      </Block>
    </Block>
  );
};

export default withTheme()(MerchantBanner);

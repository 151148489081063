import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import FireFactory from './factory';

const config = {
  development: {
    apiKey: 'AIzaSyCpTKj2BxIxBZg2lYDXstfkAO9CUyM3EeA',
    authDomain: 'mi-crm-staging-pumpkin.firebaseapp.com',
    databaseURL: 'https://mi-crm-staging-pumpkin.firebaseio.com',
    projectId: 'mi-crm-staging-pumpkin',
    storageBucket: 'mi-crm-staging-pumpkin.appspot.com',
    messagingSenderId: '429962373732'
  },
  staging: {
    apiKey: 'AIzaSyCpTKj2BxIxBZg2lYDXstfkAO9CUyM3EeA',
    authDomain: 'mi-crm-staging-pumpkin.firebaseapp.com',
    databaseURL: 'https://mi-crm-staging-pumpkin.firebaseio.com',
    projectId: 'mi-crm-staging-pumpkin',
    storageBucket: 'mi-crm-staging-pumpkin.appspot.com',
    messagingSenderId: '429962373732'
  },
  production: {
    apiKey: 'AIzaSyBCc9pUCVU6dzoz2pBW4JogxMXk8Ey93A0',
    authDomain: 'mi-crm-pumpkin.firebaseapp.com',
    databaseURL: 'https://mi-crm-pumpkin.firebaseio.com',
    projectId: 'mi-crm-pumpkin',
    storageBucket: 'mi-crm-pumpkin.appspot.com',
    messagingSenderId: '355361323753'
  }
};

const adminConfig = {
  development: {
    apiKey: 'AIzaSyCNNv-fJOZqJQT97ILktM8riUDHmAD73Ko',
    authDomain: 'mi-crm-staging-pumpkin-admin.firebaseapp.com',
    databaseURL: 'https://mi-crm-staging-pumpkin-admin.firebaseio.com',
    projectId: 'mi-crm-staging-pumpkin-admin',
    storageBucket: 'mi-crm-staging-pumpkin-admin.appspot.com',
    messagingSenderId: '1087870841267'
  },
  staging: {
    apiKey: 'AIzaSyCNNv-fJOZqJQT97ILktM8riUDHmAD73Ko',
    authDomain: 'mi-crm-staging-pumpkin-admin.firebaseapp.com',
    databaseURL: 'https://mi-crm-staging-pumpkin-admin.firebaseio.com',
    projectId: 'mi-crm-staging-pumpkin-admin',
    storageBucket: 'mi-crm-staging-pumpkin-admin.appspot.com',
    messagingSenderId: '1087870841267'
  },
  production: {
    apiKey: 'AIzaSyAa6SKAMGuN26kFva5GWEHKmmURou14cPo',
    authDomain: 'mi-crm-pumpkin-admin.firebaseapp.com',
    databaseURL: 'https://mi-crm-pumpkin-admin.firebaseio.com',
    projectId: 'mi-crm-pumpkin-admin',
    storageBucket: 'mi-crm-pumpkin-admin.appspot.com',
    messagingSenderId: '622248509459'
  }
};

const STAGE = process.env.STAGE || 'development';
firebase.initializeApp(config[STAGE]);
// const firebaseAdmin = firebase.initializeApp(adminConfig[STAGE], 'admin');

const fireService = FireFactory({ app: firebase });
// const settings = { timestampsInSnapshots: true };
// const FireStore = firebase.firestore();
// FireStore.settings(settings);
//
// export const auth = firebaseApp.auth();
// export const adminAuth = firebaseAdmin.auth();
// export const db = firebaseApp.database();
export const fireStoreService = fireService.fireStore;

const checkWindow = () => {
  if (typeof window !== 'undefined') {
    return firebase.auth();
  }
  return null;
};
export const auth = checkWindow();
export const db = firebase.database();
export default firebase;
// export const firestore = FireStore;

export const storage = firebase.storage();
export const emailAuthProvider = firebase.auth.EmailAuthProvider;

import React from 'react';
import { Image, Block } from 'components/atoms';
import { Slick } from 'components/molecules';

const BrandsSlider = ({ category }) => {
  return (
    <Block my={20}>
      <Slick
        settings={{
          infinite: true,
          slidesToShow: category.length > 6 ? 6 : category.length,
          slidesToScroll: category.length > 6 ? 6 : category.length,
          responsive: [
            {
              breakpoint: 600,
              settings: {
                slidesToShow: category.length > 3 ? 3 : category.length,
                slidesToScroll: category.length > 3 ? 3 : category.length,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: category.length > 2 ? 2 : category.length,
                slidesToScroll: category.length > 2 ? 2 : category.length,
              }
            }
          ]
        }}>
        {category.map(image => (
          <Block pt={20} pb={20}>
            <Image src={image} width={[70,100]} height={[70,100]} m={'auto'} borderRadius={22}/>
          </Block>
        ))}
      </Slick>
    </Block>
  );
};

export default BrandsSlider;

import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import { css } from '@emotion/core'

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Button, Block } from 'components/atoms';

const createCommonProps = ({ name, errors, touched }) => ({
  name,
  error: Boolean(touched[name] && errors[name]),
  helperText: touched[name] && errors[name] ? errors[name] : '',
  label: name,
  margin: 'normal',
  variant: 'outlined',
  fullWidth: true,
  required: true
});

const validationSchema = Yup.object().shape({
  message: Yup.string().required('กรุณากรอกข้อมูลให้ถูกต้อง'),
  email: Yup.string()
    .email('กรุณากรอกอีเมลล์ให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลให้ถูกต้อง')
});

const inputCss = css({
  background: 'white',
  label: {
    fontFamily: 'thai-sans-neue',
    fontSize: 18
  },
  '.MuiSelect-select.MuiSelect-outlined': {
    padding: '18.5px 14px',
    border: '1px red solid'
  },
  '.MuiFormHelperText-root.MuiFormHelperText-error': {
    background: '#fff4e9',
    paddingTop: 8,
    paddingLeft: 12,
    paddingRight: 12,
    margin: 0
  }
});

const ContactForm = styled(
  ({ initialValues, onSubmit, buttonClassName, loading }) => {
    const fields = [
      {
        name: 'email',
        label: 'อีเมลล์'
      },
      {
        name: 'message',
        label: 'ข้อความ'
      }
    ];
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          className
        }) => (
          <form
            onSubmit={handleSubmit}
          >
            {fields.map(({ name, label, options = [], ...rest }) => (
              <TextField
                key={name}
                className={inputCss}
                {...createCommonProps({
                  name,
                  errors,
                  touched
                })}
                label={label}
                value={values[name] || ''}
                onChange={event => handleChange(event)}
                multiline={name === 'message' ? true : false}
                rows={name === 'message' ? 3 : 1}
                rowsMax={name === 'message' ? 3 : 1}
                onBlur={handleBlur}
              />
            ))}
            <FormControl margin={'normal'} fullWidth>
              <Block textAlign={'end'}>
                <Button
                  type={'submit'}
                  variant={'contained'}
                  color={'primary'}
                  size={'large'}
                  loading={loading}
                  className={buttonClassName}
                  rounded
                  style={{ width: 200 }}
                  thai>
                  สมัครเลย
                </Button>
              </Block>
            </FormControl>
          </form>
        )}
      />
    );
  }
)(() => ({
  '.MuiSelect-select MuiSelect-selectMenu MuiSelect-outlined MuiInputBase-input MuiOutlinedInput-input': {
    paddingTop: 18,
    paddingBottom: 18
  }
}));

ContactForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.shape({}),
  buttonClassName: PropTypes.string
};
ContactForm.defaultProps = {
  initialValues: {},
  onSubmit: values => alert(JSON.stringify(values, null, 2)),
  buttonClassName: ''
};

export default ContactForm;

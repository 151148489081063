import { createMuiTheme } from '@material-ui/core/styles';
import baseTheme from './baseTheme';

const muiBaseTheme = createMuiTheme();

const theme = createMuiTheme({
  palette: {
    primary: baseTheme.palette.primary
  },
  typography: {
    fontFamily: [
      'thai-sans-neue',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ],
    fontSize: 18,
    body1: {
      fontSize: 18,
      '&>h4': {
        fontSize: 48,
        fontWeight: 100,
        lineHeight: '1.25px'
      },
      '&>h3': {
        fontSize: 35,
        lineHeight: '1.25px'
      }
    },
    subtitle1: {
      '&>h1': {
        fontSize: 18,
        color: '#868686'
      }
    },
    letterSpacing: 1.5,

    h1: {
      fontSize: 120
    },
    h2: {
      fontSize: 90
    },
    h3: {
      fontSize: 64
    },
    h4: {
      fontSize: 48
    },
    h5: {
      fontSize: 32
    },
    h6: {
      fontSize: 28
    },
    subtitle1: {
      fontSize: 22,
      color: '#868686'
    },
    subtitle2: {
      fontSize: 18
    },
    body2: {
      fontSize: 18
    },
    button: {
      fontSize: 18
    },
    caption: {
      fontSize: 16
    },
    overline: {
      fontSize: 16
    }
  },
  overrides: {
    MuiListItemIcon: {
      root: {
        marginRight: 0
      }
    },
    MuiIconButton: {
      root: {
        '&.icon-button--small': {
          padding: 8
        }
      }
    },
    MuiBottomNavigation: {
      root: {
        height: 60,
        boxShadow: 'none'
      }
    },
    MuiBottomNavigationAction: {
      root: {
        '&$selected': {
          paddingTop: 8
        }
      },
      label: {
        letterSpacing: 3,
        '&$selected': {
          fontSize: 14
        }
      }
    },
    MuiButton: {
      label: {
        letterSpacing: '1.5px'
      },
      contained: {
        boxShadow: 'none'
      }
    },
    MuiAppBar: {
      root: {
        '&.MuiPaper-root': {
          backgroundColor: baseTheme.get('colors.white'),
          boxShadow: '0 0 10px 0 rgba(0,0,0,0.12)'
        }
      }
    },
    MuiDrawer: {
      paper: {
        [muiBaseTheme.breakpoints.down('xs')]: {
          minWidth: 200
        }
      }
    },
    MuiCard: {
      root: {
        border: '1px solid rgba(0,0,0,.125)',
        boxShadow: '0 5px 20px 0 rgba(0,0,0,0.1)',
        borderRadius: 8
      }
    }
  }
});

export default theme;

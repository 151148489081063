import React from 'react';
import { Image, Button, Block } from 'components/atoms';
import { auth } from '../../../firebase/config';
import CircularProgress from '@material-ui/core/CircularProgress';
import { navigate } from 'gatsby';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// REDUX
import { getProfile } from 'modules/auth/actions';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { selectGetAuthStatus, selectAuthData } from 'modules/auth/selectors';
import { bindActionCreators } from 'redux';

class Profile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isUser: false,
      anchorEl: null,
      width: window.innerWidth
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    const { getProfile, profile } = this.props;
    auth.onAuthStateChanged(user => {
      if (user) {
        if (!profile) {
          this.setState({ isUser: true });
          getProfile();
        }
      } else this.setState({ isUser: false });
    });
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  renderProfileButton = () => {
    const { anchorEl, width } = this.state;
    const { profile } = this.props;
    const isOpen = Boolean(anchorEl);
    return (
      <Block>
        <Image
          css={{
            cursor: 'pointer',
            ':hover > *': { color: '#f89202 !important' }
          }}
          circular
          objectFit={'cover'}
          src={profile.photoURL}
          style={{
            border: '#f89202 2px solid',
            height: 40,
            width: 40
          }}
          onClick={e =>
            width > 600 ? this.handleMenu(e) : navigate('/MyAccount')
          }
        />
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={isOpen}
          onClose={this.handleClose}>
          <MenuItem
            onClick={() => {
              this.setState({ anchorEl: null });
              navigate('/MyAccount');
            }}>
            My Account
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.setState({ anchorEl: null });
              return auth.signOut().then(() =>{
                localStorage.setItem('showPhoneModal', 'null');
                navigate('/login')});
            }}>
            Log Out
          </MenuItem>
        </Menu>
      </Block>
    );
  };

  renderLoginButton = props => (
    <Button
      rounded
      variant={'contained'}
      color={'primary'}
      style={{
        background: 'linear-gradient(to bottom, #ffc370, #ffab34)',
        fontSize: 16,
        textTransform: 'none'
      }}
      {...props}
      onClick={() => navigate('/login')}>
      Login
    </Button>
  );

  loading = () => {
    const { getProfileStatus } = this.props;

    if (getProfileStatus.isPending) {
      return (
        <CircularProgress style={{ height: 44, width: 44 }}>
          {this.loading}
        </CircularProgress>
      );
    } else if (getProfileStatus.isFulfilled) return this.renderProfileButton();
    else return this.renderLoginButton();
  };

  render() {
    return this.loading();
  }
}

export default compose(
  connect(
    state => ({
      getProfileStatus: selectGetAuthStatus(state),
      profile: selectAuthData(state)
    }),
    dispatch =>
      bindActionCreators(
        {
          getProfile
        },
        dispatch
      )
  )
)(Profile);

import React from 'react';

//COMPONENTS
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import withStyles from '@material-ui/core/styles/withStyles';
import { Text, Image } from 'components/atoms';
import LocationOn from '@material-ui/icons/LocationOn';
import Phone from '@material-ui/icons/Phone';

const styles = theme => {
  return {
    media: {
      padding: '28.125% 0',
      height: 0,
      position: 'relative',
      overflow: 'hidden'
    },
    logo: {
      width: 32,
      height: 32,
      borderRadius: 4,
      marginRight: 10
    },
    content: {
      padding: '12px 8px 12px 8px',
      paddingBottom: '12px !important'
    },
    flexBox: {
      display: 'flex',
      alignItems: 'center'
    },
    infoBox: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 4
    },
    textMargin: {
      marginLeft: 7
    },
    brandDetail: {
      flex: '1 1 auto',
      minWidth: 0,
    },
    description: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
  };
};

class CardInfo extends React.Component {
  state = {};

  componentDidMount() {}
  render() {
    const {
      classes,
      cover,
      logo,
      name,
      location,
      phoneNum,
      description,
      primaryColor
    } = this.props;

    return (
      <Card style={{ height: '100%' }}>
        <CardMedia className={classes.media}>
          <Image
            position={'absolute'}
            top={0}
            height={'100%'}
            width={'100%'}
            alt="merchant card"
            src={cover}
          />
        </CardMedia>
        <CardContent className={classes.content}>
          <div className={classes.flexBox}>
            <Image width={32} height={32} src={logo} className={classes.logo} />
            <div className={classes.brandDetail}>
              <Text.Heading fontSize={15} fontWeight={'bold'}>
                {name}
              </Text.Heading>
              <Text color="grey" fontSize={12} className={classes.description}>
                {description}
              </Text>
            </div>
          </div>
          <div className={classes.infoBox}>
            <LocationOn style={{ fontSize: 16, color: primaryColor }} />
            <Text fontSize={12} color={'grey'} className={classes.textMargin}>
              {location || '-'}
            </Text>
          </div>
          <div className={classes.infoBox}>
            <Phone style={{ fontSize: 16, color: primaryColor }} />
            <Text fontSize={12} color={'grey'} className={classes.textMargin}>
              {phoneNum || '-'}
            </Text>
          </div>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(CardInfo);

import React from 'react';
import styled from '@emotion/styled';
import { ClassNames } from '@emotion/core';
import { Image, Text, Block, Button } from 'components/atoms';
import moment from 'moment';
import get from 'lodash/get';
import { auth } from 'appFirebase/config';

// ICONS
import Edit from '@material-ui/icons/Edit';
import MailOutlined from '@material-ui/icons/MailOutlined';
import CakeOutlined from '@material-ui/icons/CakeOutlined';
import LockOutlined from '@material-ui/icons/LockOutlined';
import PhoneOutlined from '@material-ui/icons/PhoneOutlined';

import Genders from 'assets/genders.svg';

const Profile = styled(
  ({
    className,
    birthday,
    email,
    displayName,
    photoURL,
    editProfile,
    changePassword,
    addPhoneNumber,
    phoneNumber,
    gender
  }) => {
    const hasPassword = () => {
      const providerDatas = get(auth.currentUser, 'providerData', []);
      const providerPassword = providerDatas.filter(
        providerData => providerData.providerId === 'password'
      );
      if (providerPassword.length !== 0) {
        return true;
      }
      return false;
    };

    const renderPhoneFormat = phoneNumber => {
      let str =
        phoneNumber.substring(0, 3) +
        '-' +
        phoneNumber.substring(3, 6) +
        '-' +
        phoneNumber.substring(6, 10);
      return str;
    };

    const renderGender = gender => {
      if (gender) {
        if (gender === 'male') return 'ชาย';
        else if (gender === 'female') return 'หญิง';
        else if (gender === 'other') return 'อื่นๆ';
        else return 'ไม่ระบุ';
      }
      return null;
    };

    return (
      <ClassNames>
        {({ cx }) => (
          <Block
            className={cx('privilege-box', className)}
            width={['initial', 370]}>
            <Block
              background={'white'}
              border={'1px solid #d0d0d0'}
              borderRadius={'50%'}>
              <Image
                src={photoURL}
                objectFit={'cover'}
                borderRadius={'50%'}
                border={'7px solid white'}
                height={154}
                width={154}
                circular
              />
            </Block>
            <Text fontSize={[30, 44]} fontWeight={'bold'} mt={10} mb={'10px'}>
              {displayName}
            </Text>
            <Block.Flex
              alignItems={'center'}
              onClick={editProfile}
              style={{ cursor: 'pointer' }}>
              <Edit
                style={{ fontSize: 26, color: '#feb415', marginRight: 7 }}
              />
              <Text fontSize={24} color={'#fabd67'}>
                Edit profile
              </Text>
            </Block.Flex>
            <Block py={30} width={'100%'}>
              <Block className={'detail'}>
                <Block.Flex alignItems={'center'}>
                  <MailOutlined />
                  <Text>{email !== '' ? email : 'Not set'}</Text>
                </Block.Flex>
              </Block>
              <Block className={'detail'}>
                <Block.Flex alignItems={'center'}>
                  <CakeOutlined />
                  <Text>
                    {typeof birthday !== 'undefined'
                      ? moment(birthday, 'MM/DD/YYYY').format('MMM DD, YYYY')
                      : 'Not set'}
                  </Text>
                </Block.Flex>
              </Block>
              <Block className={'detail'}>
                <Block.Flex alignItems={'center'}>
                  <Image src={Genders} width={30} height={30} />
                  <Text>{renderGender(gender) || 'Not set'}</Text>
                </Block.Flex>
              </Block>
              <Block className={'detail'}>
                <Block.Flex alignItems={'center'}>
                  {phoneNumber ? (
                    <Block.Flex
                      width={'100%'}
                      justifyContent={'space-between'}
                      flexDirection={['column', 'row']}>
                      <Block.Flex justifyContent={'flex-start'}>
                        <PhoneOutlined />
                        <Text>{renderPhoneFormat(phoneNumber)}</Text>
                      </Block.Flex>
                      <Button
                        variant={'outlined'}
                        color={'primary'}
                        onClick={addPhoneNumber}>
                        Edit number
                      </Button>
                    </Block.Flex>
                  ) : (
                    <Block.Flex width={'100%'}>
                      <Block.Flex justifyContent={'flex-start'}>
                        <PhoneOutlined />
                      </Block.Flex>
                      <Button
                        ml={20}
                        variant={'contained'}
                        color={'primary'}
                        onClick={addPhoneNumber}>
                        Add phone number
                      </Button>
                    </Block.Flex>
                  )}
                </Block.Flex>
              </Block>

              {hasPassword() && (
                <Block className={'detail'}>
                  <Block.Flex
                    alignItems={'center'}
                    onClick={changePassword}
                    style={{ cursor: 'pointer' }}>
                    <LockOutlined />
                    <Text>Change password</Text>
                  </Block.Flex>
                </Block>
              )}
            </Block>
          </Block>
        )}
      </ClassNames>
    );
  }
)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  wordBreak: 'break-word',
  '.link': {
    textDecoration: 'none'
  },
  '.detail': {
    width: '100%',
    padding: '20px 0px',
    borderBottom: '2px solid #F0F0F0'
  },
  '.detail p': {
    marginLeft: 20,
    fontSize: 27,
    letterSpacing: 0
  },
  '.detail svg': {
    fontSize: 30,
    opacity: 0.3,
    color: 'black'
  }
}));

export default Profile;

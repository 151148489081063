import React from 'react';
import { Block, Text, GatsbyImage } from 'components/atoms';
import styled from '@emotion/styled';
import withTheme from 'hocs/withTheme';
import Grid from '@material-ui/core/Grid';

const newCustomersGroup = 'assets/new-customers-group.png';
const oldCustomersGroup = 'assets/old-customers-group.png';
const newCustomersShop = 'assets/new-customers-shop.png';
const oldCustomersShop = 'assets/old-customers-shop 2.png';
const crmGraph = 'assets/20%graph.png';

const MerchantDiv = styled('div')({
  '.highlightSpan': {
    color: '#fb970b'
  },
  '.secondSection': {
    marginTop: 30
  },
  marginTop: 30
});

const MerchantCrmSection = () => {
  return (
    <MerchantDiv>
      <Block display={'flex'} alignItems={'baseline'} justifyContent={'center'}>
        <Text
          fontSize={[30, 35]}
          color={'#893f18'}
          fontWeight={'bold'}
          style={{ textDecoration: 'underline' }}>
          CRM
        </Text>
        <Text fontSize={[25, 30]} color={'#893f18'} fontWeight={'bold'}>
          &nbsp;ของคนรุ่นใหม่
        </Text>
      </Block>
      <Block
        maxWidth={1200}
        pt={30}
        pb={30}
        m={'auto'}
        pr={[30, 50]}
        pl={[30, 50]}>
        <Block p={30} border={'1px #cfcfcf dashed'} borderRadius={10}>
          <Grid
            container
            spacing={24}
            direction="row"
            justify="center"
            alignItems="center">
            <Grid item xs={12} md={4}>
              <Block display={'flex'} alignItems={'baseline'} justifyContent={'center'}>
                <Text
                  fontSize={[30, 35]}
                  color={'#535353'}
                  fontWeight={'bold'}
                  color={'#fb970b'}>
                  68%&nbsp;
                </Text>
                <Text fontSize={[23, 30]} color={'#535353'} fontWeight={'bold'} >
                  ของคนรุ่นใหม่
                </Text>
              </Block>
              <Text
                fontSize={[14, 16, 18, 25]}
                color={'#535353'}
                fontWeight={'bold'}
                textAlign={'center'}>
                จะไม่ซื้อ ถ้าไม่มีโปรโมชั่นสำหรับลูกค้า
              </Text>
            </Grid>
            <Grid item xs={12} md={4}>
              <GatsbyImage
                src={oldCustomersShop}
                width={[200,300, 350]}
                m={'auto'}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <GatsbyImage
                src={newCustomersShop}
                width={[140, 180]}
                m={'auto'}
              />
            </Grid>
          </Grid>
        </Block>
        <Grid
          container
          spacing={24}
          direction="row"
          justify="center"
          alignItems="center"
          className={'secondSection'}>
          <Grid item xs={12} md={6}>
            <Block
              pl={[20, 50]}
              pr={[20, 50]}
              pt={[20, 30]}
              pb={[20, 30]}
              border={'1px #cfcfcf dashed'}
              borderRadius={10}
              position={'relative'}
              height={[205, 265]}>
              <Text
                fontSize={[23, 30]}
                textAlign={'center'}
                fontWeight={'bold'}
                mb={[20, 30]}>
                โอกาสในการขาย
              </Text>
              <Block
                display={'flex'}
                justify={'space-between'}
                alignItems={'flex-end'}>
                <GatsbyImage src={newCustomersGroup} width={[65, 120]} />
                <Block
                  flex={1}
                  mr={'8px'}
                  borderBottom={'1px #cfcfcf dashed'}
                />
                <Block textAlign={'right'}>
                  <Text color={'#c4c4c4'} fontSize={[13, 18]} lineHeight={2.4}>
                    ลูกค้าใหม่
                  </Text>
                  <Text
                    fontSize={[20, 30]}
                    fontWeight={'bold'}
                    lineHeight={0.5}>
                    5-20%
                  </Text>
                </Block>
              </Block>
              <Block />
              <Block
                display={'flex'}
                justify={'space-between'}
                alignItems={'flex-end'}
                mt={35}>
                <GatsbyImage src={oldCustomersGroup} width={[110, 200]} />
                <Block
                  flex={1}
                  ml={'8px'}
                  mr={'8px'}
                  borderBottom={'1px #cfcfcf dashed'}
                />
                <Block textAlign={'right'}>
                  <Text color={'#c4c4c4'} fontSize={[13, 18]} lineHeight={2.4}>
                    ลูกค้าเก่า
                  </Text>
                  <Text
                    fontSize={[20, 30]}
                    fontWeight={'bold'}
                    lineHeight={0.5}>
                    60-70%
                  </Text>
                </Block>
              </Block>
              <Block />
            </Block>
          </Grid>
          <Grid item xs={12} md={6}>
            <Block
              pl={[20, 50]}
              pr={[20, 50]}
              pt={30}
              pb={30}
              border={'1px #cfcfcf dashed'}
              borderRadius={10}
              height={['auto', 265]}
              position={'relative'}>
              <Text
                fontSize={[23, 30]}
                textAlign={'center'}
                fontWeight={'bold'}>
                ลูกค้าที่กลับมาซื้อซ้ำช่วยเพิ่ม
              </Text>
              <Text
                fontSize={[23, 30]}
                textAlign={'center'}
                fontWeight={'bold'}>
                ยอดขายกว่า 20%
              </Text>
              <GatsbyImage src={crmGraph} m={'auto'} width={[200, 350, 410]} />
            </Block>
          </Grid>
        </Grid>
      </Block>
    </MerchantDiv>
  );
};

export default withTheme()(MerchantCrmSection);

import React from 'react'
import withDampCallback from 'hocs/withDampCallback'

import Modal from './Modal'

class AutoClose extends React.Component {

  componentWillReceiveProps(nextProps, nextContext) {
    const { open, startCountDown } = this.props
    const { open: nextOpen } = nextProps
    if (!open && nextOpen) {
      return startCountDown()
    }
  }

  render() {
    const { renderContent, secondLeft, ...props } = this.props
    return (
      <Modal
        {...props}
        {...renderContent && {
          content: renderContent(secondLeft)
        }}
      />
    )
  }
}

export default withDampCallback()(AutoClose)

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Block, Image, Text, GatsbyImage } from 'components/atoms';
import { MerchantContractForm } from 'components/forms/FormItems';
import logo from 'assets/logo.svg';
import cloudApi from 'api/cloudApi';

const ContractHeader = 'assets/contract-header2.png';
const Contactus = 'assets/Contactus-bg.png';
const PumpkinTeam = 'assets/pumpkin-team.png';

class MerChantContract extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      finishSendingEmail: false
    };
  }

  sendEmail = values => {
    this.setState({ loading: true, email: values.email });
    return cloudApi
      .addContractToGoogleSheet(values)
      .then(() => this.setState({ loading: false, finishSendingEmail: true }));
  };
  render() {
    const { finishSendingEmail, loading } = this.state;
    return (
      <Block
        background={'#fff4e9'}
        id="MerchantContractForm"
        style={{ overflow: 'hidden' }}
        // borderRadius={['inherit', 'inherit', '0 0 20% 20%', '0 0 30% 30%']}
        pb={[60, 0]}
        css={{
          borderRadius: 'inherit',
          '@media only screen and (min-width: 768px)': {
            borderRadius: '0 0 20% 20%'
          },
          '@media only screen and (min-width: 1024px)': {
            borderRadius: '0 0 30% 30%'
          }
        }}>
        <Block
          position={'relative'}
          p={[30, 60]}
          maxWidth={['initial', 700, 960, 1200]}
          mx={'auto'}>
          <GatsbyImage
            src={ContractHeader}
            m={'auto'}
            width={[200, 300, 420]}
          />
          <Image
            src={logo}
            width={[100, 150, 220]}
            m={'auto'}
            mt={15}
            mb={15}
          />
          <Grid
            container
            spacing={24}
            direction="row"
            justify="center"
            alignItems="center"
            style={{ zIndex: 2, position: 'relative' }}>
            <Grid item xs={12} md={6}>
              <GatsbyImage
                maxWidth={[280, 360, 440]}
                src={PumpkinTeam}
                m={'auto'}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {finishSendingEmail ? (
                <Block textAlign={'center'}>
                  <Text
                    fontFamily={'thai'}
                    pt={20}
                    fontSize={30}
                    fontWeight={'bold'}>
                    ขอบคุณที่สนใจเข้าร่วมกับ Pumpkin
                  </Text>
                </Block>
              ) : (
                <MerchantContractForm
                  onSubmit={this.sendEmail}
                  initialValues={{}}
                  loading={loading}
                />
              )}
            </Grid>
            <Grid />
          </Grid>
          <GatsbyImage
            src={Contactus}
            position={'absolute'}
            bottom={[-60, 0]}
            maxWidth={[600, 1200]}
            zIndex={1}
            left={[0, 'auto']}
          />
        </Block>
      </Block>
    );
  }
}

export default MerChantContract;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ClassNames } from '@emotion/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ButtonGroup from './ButtonGroup';
import { space } from 'styled-system';
import { reduceStyles } from 'helpers/functions';

const name = 'pumpkin-button';
const CustomButton = styled(
  ({
     classes,
     className,
     flat,
     rounded,
     inverted,
     loading,
     disabled,
     children,
     color,
     active,
     provider,
     ...rest
   }) => {
    return (
      <ClassNames>
        {({ cx }) => (
          <Button
            className={cx(
              'button',
              active && 'active',
              flat && 'flat',
              rounded && 'rounded',
              loading && 'loading',
              inverted && 'inverted',
              provider,
              rest.variant === 'outlined' && 'outlined',
              rest.variant === 'contained' && 'contained',
              className
            )}
            {...rest}
            {...(loading || disabled ? { disabled: true } : { color })}>
            {children}
            {loading && (
              <CircularProgress
                className={'circular-progress'}
                size={20}
                color={'inherit'}
              />
            )}
          </Button>
        )}
      </ClassNames>
    );
  }
)(
  {
    letterSpacing: 1,
    '&.button svg': {
      paddingRight: 5
    },
    '&.flat': {
      boxShadow: 'none'
    },
    '&.rounded': {
      borderRadius: 30
    },
    '&.loading': {
      position: 'relative'
    },
    '&.loading span': {
      visibility: 'hidden'
    },
    '&.contractForm': {
      width: 200
    },
    '&.loading .circular-progress': {
      visibility: 'visible',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginLeft: '-10px',
      marginTop: '-10px'
    }
  },
  props => ({
    '&.button': space(props)
  }),
  ({ theme }) => ({
    '&.line': {
      background: '#00c300',
      ':hover': {
        background: '#009000'
      }
    },
    '&.inverted.contained': {
      backgroundColor: '#ffffff',
      span: {
        color: '#f89202'
      }
    },
    '&.inverted.outlined': {
      borderColor: '#ffffff',
      span: {
        color: '#ffffff'
      }
    },
    ...reduceStyles(`&.${name}`)
  })
);

CustomButton.presets = {
  contained: {
    variant: 'contained',
    flat: true
  },
  outlined: {
    variant: 'outlined'
  }
};
CustomButton.Group = ButtonGroup;
CustomButton.Contain = props => (
  <CustomButton {...props} {...CustomButton.presets.contained} />
);
CustomButton.OutLined = props => (
  <CustomButton {...props} {...CustomButton.presets.outlined} />
);
CustomButton.Delete = props => (
  <CustomButton
    {...props}
    {...CustomButton.presets.contained}
    color={'secondary'}
  />
);
CustomButton.propTypes = {
  loading: PropTypes.bool,
  flat: PropTypes.bool
};
CustomButton.defaultProps = {
  loading: false,
  flat: true
};

export default CustomButton;

const ROUTES = {
  landing: {
    getPath: () => '/'
  },
  explore: {
    getPath: () => '/shop-category'
  },
  login: {
    getPath: () => '/login'
  },
  getApp: {
    getPath: () => '/get-app'
  },
  myCards: {
    getPath: () => '/qr-code'
  },
  cardDetail: {
    pattern: '/brands/:brandId',
    getPath: id => `/brands/${id}`
  },
  notification: {
    getPath: () => '/notification'
  },
  notiDetail: {
    pattern: '/notification/:notificationId',
    getPath: id => `/notification/${id}`
  },
  survey: {
    getPath: () => '/survey'
  },
  termAndConditions: {
    pattern: '/term-and-condition-app',
    getPath: () => '/term-and-condition-app'
  },
  privacyPolicy: {
    pattern: '/privacy-policy-fb',
    getPath: () => '/privacy-policy-fb'
  },
  lineAppLogin: {
    pattern: '/line-app-login',
    getPath: () => '/line-app-login'
  }
};

export default ROUTES;

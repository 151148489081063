import React from 'react';

export const initialAuth = {
  me: null,
  profile: null,
  isFindingAuth: true,
  isLoggingIn: false,
  setIsLoggingIn: () => {},
  setProfile: () => {},
  showRequestStamp: false,
  setShowRequestStamp: () => {},
};

const AuthContext = React.createContext(initialAuth);

export const AuthProvider = AuthContext.Provider;

export const AuthConsumer = AuthContext.Consumer;

export default AuthContext;

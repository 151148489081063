const env = process.env.STAGE;

const host = {
  development: 'http://localhost:5000/mi-crm-staging-pumpkin/us-central1',
  staging: 'https://us-central1-mi-crm-staging-pumpkin.cloudfunctions.net',
  production: 'https://us-central1-mi-crm-pumpkin.cloudfunctions.net'
};
const website = {
  development: 'https://localhost:8000',
  staging: 'https://staging.pumpkinapp.co',
  production: 'https://pumpkinapp.co'
};

const generateState = ({ cashierId, brandId, surveyId, goto }) => {
  if (goto) {
    let currentPath = goto.slice(6).replace('&', ';');
    return `${currentPath}`;
  } else if (!cashierId && !brandId && !surveyId) {
    return 'welcome';
  }
  return `${cashierId ? `cashier:${cashierId};` : ''}${
    brandId ? `brand:${brandId};` : ''
  }${surveyId ? `surveyId:${surveyId}` : ''}`;
};
const createLineUrl = environment => ({ cashierId, brandId, surveyId, goto }) =>
  `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1606766442&redirect_uri=${
    host[environment]
  }/api/auth/line&state=${generateState({
    cashierId,
    brandId,
    surveyId,
    goto
  })}&scope=openid email profile`;
export const ADD_LINE_LINK = 'http://line.me/ti/p/~@pumpkinapp';
export const LINE_LOGO =
  'https://pbs.twimg.com/profile_images/507080899083501568/LdbTOClD.png';
export const VERSION = process.env.VERSION;
export const HOST = host[env];
export const WEBSITE = website[env];
export const getLineURL = createLineUrl(env);

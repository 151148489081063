import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ClassNames } from '@emotion/core';
import { Image, Text, Block } from 'components/atoms';

const FeatureBox = styled(
  ({ featureDescription, imagePath, featureTitle, className }) => (
    <ClassNames>
      {({ cx }) => (
        <Block className={cx('feature-box', className)}>
          <Block.Flex
            borderRadius={'50%'}
            width={121}
            height={121}
            className={'image-box'}>
            <Image.Dynamic
              src={imagePath}
              className={'previewImage'}
              objectFit={'contain'}
              width={105}
              height={105}
            />
          </Block.Flex>
          <Block
            className={'text-box'}
            borderRadius={15}
            p={35}
            pt={60}
            textAlign={'center'}
            width={300}>
            <Text fontWeight={'bold'} fontSize={22} fontFamily={'thai'} pb={12}>
              {featureTitle}
            </Text>
            <Text fontFamily={'thai'} fontSize={18} letterSpacing={'1px'}>
              {featureDescription}
            </Text>
          </Block>
        </Block>
      )}
    </ClassNames>
  )
)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  position: 'relative',
  '.number-box': {
    backgroundColor: '#fe9526',
    borderRadius: 50
  },
  '.text-box': {
    backgroundColor: 'white',
    textAlign: 'center',
    zIndex: 1,
    marginTop: 100
  },
  '.image-box': {
    backgroundColor: '#fff9ef',
    border: '8px solid white',
    position: 'absolute',
    top: 40,
    zIndex: 2
  }
}));

FeatureBox.propTypes = {
  stepNum: PropTypes.string,
  imagePath: PropTypes.string,
  stepTitle: PropTypes.string
};
FeatureBox.propTypes = {
  stepNum: '',
  imagePath: '',
  stepTitle: ''
};
export default FeatureBox;

import React from 'react';
import { compose, withState } from 'recompose';
import { css, ClassNames } from '@emotion/core';

const TouchWrapper = compose(withState('touching', 'setTouching', false))(
  ({ touching, setTouching, children, className, ...rest }) => {
    return (
      <ClassNames>
        {({ cx }) => (
          <div
            className={cx(
              'TouchWrapper',
              css({
                transition: '0.3s',
                ...(touching && {
                  transform: 'scale(0.95)'
                })
              }),
              className
            )}
            onTouchStart={() => setTouching(true)}
            onTouchEnd={() => setTouching(false)}
            onBlur={() => setTouching(false)}
            {...rest}
          >
            {children}
          </div>
        )}
      </ClassNames>
    );
  }
);

export default TouchWrapper;

import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import { Block, Button, Text } from 'components/atoms';
import firebase from 'appFirebase/config';
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange(values.value);
      }}
      format="### - ### - ####"
      placeholder="0XX - XXX - XXXX"
      mask=" "
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

const PhoneNumberForm = ({ handleSubmit, profile }) => {
  const [values, setValues] = React.useState(null);
  const [active, setActive] = React.useState(false);
  const [sendNumberLoading, setSendNumberLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const OnSubmit = values => {
    if (values !== profile.phoneNumber) {
      setSendNumberLoading(true);
      const newPhoneNumber = '+66' + values.substring(1);
      firebase.auth().languageCode = 'en';
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'confirm-button',
        {
          size: 'invisible',
          callback: function (response) {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            console.log(response);
          }
        }
      );
      var appVerifier = window.recaptchaVerifier;
      //+66622828626'
      return firebase
        .auth()
        .currentUser.linkWithPhoneNumber(newPhoneNumber, appVerifier)
        // .then(data => console.log('data', data));
        .then(confirmationResult => {
          console.log('Sms sent');
          console.log(confirmationResult);
          setSendNumberLoading(false);
          handleSubmit({
            otpModal: true,
            addPhoneNumber: false,
            confirmationResult,
            phoneNumber: newPhoneNumber,
            appVerifier
          });
        })
        .catch(function (error) {
          if (error.code === 'auth/too-many-requests') {
            setErrorMessage(
              'We have blocked all requests from this device due to unusual activity. Try again later.'
            );
          }
        });
    } else {
      setErrorMessage('Please use new phone number');
    }
  };
  return (
    <Block>
      <form>
        <Block
          my={10}
          css={{
            '.MuiFormControl-root': {
              width: '-webkit-fill-available',
              '.MuiInput-root': {
                input: {
                  fontSize: 'x-large'
                }
              }
            }
          }}>
          <Text fontSize={24} fontWeight={500}>
            Enter a phone number
          </Text>
          <TextField
            value={values}
            onChange={value => {
              setValues(value);
            }}
            id="formatted-numberformat-input"
            InputProps={{
              inputComponent: NumberFormatCustom
            }}
          />
          {errorMessage !== null && <Text color="red">*{errorMessage}</Text>}
        </Block>
        <Button.Contain
          type="submit"
          color={'primary'}
          id="confirm-button"
          fullWidth
          disabled={!values || values.length < 10}
          loading={sendNumberLoading}
          onClick={e => {
            e.preventDefault();
            return OnSubmit(values);
          }}>
          Send Number
        </Button.Contain>
      </form>
    </Block>
  );
};

export default PhoneNumberForm;

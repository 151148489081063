import React from 'react';
import styled from '@emotion/styled';
import { ClassNames, keyframes } from '@emotion/core';

import { Block } from 'components/atoms';

import pumpkinSuperman from 'assets/mascot/pumpkin-superman.png';
import aura from 'assets/mascot/aura.png';
import scatter from 'assets/mascot/scatter.png';
import scatter2 from 'assets/mascot/scatter2.png';

const rotate = keyframes({
  '0%': {
    transform: 'rotate(0deg) scale(1) translateX(0)'
  },
  '50%': {
    transform: 'rotate(180deg) scale(1.2) translateX(-20px)'
  },
  '100%': {
    transform: 'rotate(360deg) scale(1) translateX(0)'
  }
});

const scaleFade = keyframes({
  '0%': {
    opacity: 1,
    transform: 'scale(0) rotate(0deg)'
  },
  '100%': {
    transform: 'scale(1.5) rotate(180deg)',
    opacity: 0
  }
});

const Mascot = styled(({ className, ...props }) => {
  return (
    <ClassNames>
      {({ cx }) => (
        <Block className={cx('Mascot', className)} {...props}>
          <img className={'scatter'} alt={'scatter'} src={scatter}/>
          <img className={'scatter2'} alt={'scatter2'} src={scatter2}/>
          <img className={'aura'} alt={'aura'} src={aura}/>
          <img className={'superman'} alt={'superman'} src={pumpkinSuperman}/>
        </Block>
      )}
    </ClassNames>
  );
})({
  position: 'relative',
  transition: '0.4s',
  '.scatter': {
    position: 'absolute',
    width: '100%',
    opacity: 0,
    animation: `${scaleFade} 5s ease infinite`
  },
  '.scatter2': {
    position: 'absolute',
    width: '100%',
    opacity: 0,
    animation: `${scaleFade} 5s ease infinite`,
    animationDelay: '2.5s'
  },
  '.aura': {
    marginTop: -20,
    position: 'absolute',
    width: '100%',
    animation: `${rotate} 4s linear infinite`
  },
  '.superman': {
    width: '40%',
    position: 'relative',
    display: 'block',
    margin: 'auto',
    marginTop: 20,
    zIndex: 5
  }
});

Mascot.propTypes = {};
Mascot.defaultProps = {};

export default Mascot;

import React from 'react';
import getWindowHeight from './getWindowHeight';
import { checkStyle } from './checkStyle';

export default class Div100vh extends React.Component {
  state = {
    style: {}
  };

  // On mount and window resize converts rvh values to px (if there are any).
  // Also, adds `height: 100rvh` if height is not specified at all
  updateStyle = () => {
    const convertedStyle = checkStyle(this.props.style, getWindowHeight());
    this.setState({ style: convertedStyle });
  };

  componentDidMount() {
    this.updateStyle();
    window.addEventListener('resize', this.updateStyle);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateStyle);
  }

  render() {
    return <div {...this.props} style={this.state.style} />;
  }
}

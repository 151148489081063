import isArray from 'lodash/isArray';

const ImageFactory = () => {
  const storageHost =
    'https://firebasestorage.googleapis.com/v0/b/mi-crm-pumpkin-admin.appspot.com/o';

  const LANDING = {
    step1: ['assets', 'dashboard', 'step1.png'],
    step2: ['assets', 'dashboard', 'step2.png'],
    step3: ['assets', 'dashboard', 'step3.png'],
    feature1: ['assets', 'dashboard', 'feature-1.png'],
    feature2: ['assets', 'dashboard', 'feature-2.png'],
    feature3: ['assets', 'dashboard', 'feature-3.png'],
    feature4: ['assets', 'dashboard', 'feature-4.png'],
    feature5: ['assets', 'dashboard', 'feature-5.png'],
    feature6: ['assets', 'dashboard', 'feature-6.png'],
    desktop: ['assets', 'dashboard', 'desktop.png'],
    phone: ['assets', 'dashboard', 'phone.png'],
    pumpkinMerchant: ['assets', 'dashboard', 'pumpkin-merchant.png'],
    pumpkinTeam: ['assets', 'dashboard', 'pumpkin-team.png'],
    joinUs: ['assets', 'dashboard', 'join-us.png'],
    town: ['assets', 'dashboard', 'town.png'],
    featureTop: ['assets', 'dashboard', 'feature-top-bg.png'],
    featureBottom: ['assets', 'dashboard', 'feature-bottom-bg.png']
  };

  const toBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.onload = e => callback(e.target.result);
    reader.readAsDataURL(file);
  };

  const getUrl = ({ path = [], token = '' }) => {
    // ex. use with LANDING getUrl({ path: LANDING.step1 })
    if (!isArray(path)) {
      throw new Error('folder must be an array');
    }
    return `${storageHost}/${path.join('%2F')}?alt=media&token=${token}`;
  };

  return {
    LANDING,
    toBase64,
    getUrl
  };
};

export default ImageFactory();

import React from 'react';
import { NoResult, Block, Loader ,Text} from 'components/atoms';
import CouponCard from './CouponCard';
import InfiniteScroll from 'react-infinite-scroller';
import CircularProgress from '@material-ui/core/CircularProgress/index';
import moment from 'moment';

// REDUX
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import {
  selectCoupons,
  selectCouponsStatus
} from 'modules/notification/selectors';
import { getCoupons } from 'modules/notification/actions';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


class CouponsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHistoryCoupon: false,
      showAvailableCoupon: true,
    };
  }

  componentDidMount() {
    const { coupons } = this.props;
    if (!coupons || !coupons.length) {
      this.props.getCoupons();
    }
  }

  getCouponCanUse = () => {
    const { coupons } = this.props;
    const redeemedCoupons = coupons.filter(
      coupon =>
        !coupon.markAsUsed &&
        moment(coupon.expiredDate).isAfter(moment().format()),
    );
    return redeemedCoupons;
  };

  getCouponCanNotUse = () => {
    const { coupons } = this.props;
    const historyRedeemedCoupons = coupons.filter(
      coupon =>
        coupon.markAsUsed ||
        moment(coupon.expiredDate).isBefore(moment().format()),
    );
    return historyRedeemedCoupons;
  };

  render() {
    const { coupons, getCouponsStatus } = this.props;
    const {showAvailableCoupon, showHistoryCoupon} = this.state
    if (getCouponsStatus.isFulfilled && coupons.length === 0)
      return <NoResult />;

    return (
      <Block.Flex
        justifyContent={'center'}
        flexDirection={['column', 'column', 'row']}
        flexWrap={'wrap'}
        p={['0px', 20]}>
        {!getCouponsStatus.isFulfilled && <Loader />}
        {
          getCouponsStatus.isPending ?
          <NoResult />
          :
          <InfiniteScroll
          loader={
            <Block.Flex key={'loader'} justifyContent={'center'} p={20}>
              <CircularProgress size={20} thickness={5} />
            </Block.Flex>
          }>
        <ExpansionPanel expanded={showAvailableCoupon} onChange={()=> this.setState({showAvailableCoupon: !showAvailableCoupon})}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Text>Available</Text>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
        <Block
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            flexWrap={'wrap'}>
            {coupons.length > 0 &&
              this.getCouponCanUse().map(coupon => (
                <CouponCard
                  title={coupon.title}
                  image={coupon.image}
                  expiredDate={coupon.expiredDate}
                />
              ))}
            </Block>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <Block mt={[20,50]}>
      <ExpansionPanel expanded={showHistoryCoupon} onChange={()=> this.setState({showHistoryCoupon: !showHistoryCoupon})}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Text>History</Text>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
        <Block
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            flexWrap={'wrap'}>
            {coupons.length > 0 &&
              this.getCouponCanNotUse().map(coupon => (
                <CouponCard
                  title={coupon.title}
                  image={coupon.image}
                  expiredDate={coupon.expiredDate}
                />
              ))}
            </Block>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      </Block>
        </InfiniteScroll>
        }
        
      </Block.Flex>
    );
  }
}

export default compose(
  connect(
    state => ({
      coupons: selectCoupons(state),
      getCouponsStatus: selectCouponsStatus(state)
    }),
    dispatch =>
      bindActionCreators(
        {
          getCoupons
        },
        dispatch
      )
  )
)(CouponsSection);

import React from 'react'
import isObject from 'lodash/isObject'
import Image from './Image'
import imageService from 'helpers/image'

class DynamicImage extends React.Component {

  state = {
    resultImage: ''
  }

  componentDidMount() {
    const { src } = this.props
    this.setImage(src)
  }

  componentWillReceiveProps(nextProps) {
    const { src } = nextProps
    this.setImage(src)
  }

  setImage = src => {
    if (isObject(src)) {
      imageService.toBase64(src, resultImage => {
        // set state when image is transform to base64
        this.setState({ resultImage })
      })
    }
  }

  render() {
    const { resultImage } = this.state
    const { src, ...rest } = this.props
    return (
      <Image
        // if src is object (file type) use resultImage from state
        src={isObject(src) ? resultImage : src}
        {...rest}
      />
    )
  }
}

DynamicImage.propTypes = Image.propTypes
DynamicImage.defaultProps = Image.defaultProps

export default DynamicImage

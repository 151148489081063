import React from 'react';
import { Image, Text, Block } from 'components/atoms';

class BrandCategoryButton extends React.Component {
  render() {
    const { image, name, total, active } = this.props;
    return (
      <Block.Flex
        alignItems={'center'}
        background={'white'}
        p={'2px 16px 2px 2px'}
        borderRadius={50}
        border={active ? '2px solid #f69636' : '2px solid #cccccc'}
        style={{ cursor: 'pointer' }}>
        <Image src={image} width={40} height={40} borderRadius={50} />
        <Block ml={'6px'}>
          <Text
            lineHeight={'20px'}
            fontWeight={'bold'}
            color={active ? 'primary' : '#cccccc'}
            fontSize={22}
            letterSpacing={0}>
            {name}
          </Text>
          <Text
            lineHeight={1}
            fontWeight={'bold'}
            color={active ? 'primary' : '#cccccc'}
            fontSize={16}>
            {total} ร้าน
          </Text>
        </Block>
      </Block.Flex>
    );
  }
}

export default BrandCategoryButton;

import React from 'react';

// COMPONENTS
import withStyles from '@material-ui/core/styles/withStyles';
import { Text } from 'components/atoms';

const styles = theme => {
  return {
    badge: {
      position: 'absolute',
      top: 3,
      right: 26,
      width: 20,
      height: 20,
      textAlign: 'center',
      backgroundColor: 'red',
      borderRadius: '50%',
      color: 'white',
      zIndex: 2,
      left: '50%'
    }
  };
};

class BadgeNumber extends React.Component {
  calculateBadge = () => {
    const { surveys } = this.props;
    if (surveys)
      return surveys.filter(survey => !survey.markAsSubmitted).length;
    return 0;
  };
  render() {
    const { classes } = this.props;
    return (
      <div
        className={classes.badge}
        style={{ display: this.calculateBadge() ? 'initial' : 'none' }}>
        <Text
          color={'white'}
          style={{ lineHeight: '20px', fontSize: 16, fontWeight: 700 }}>
          {this.calculateBadge()}
        </Text>
      </div>
    );
  }
}

export default withStyles(styles)(BadgeNumber);

import React from 'react';
import { Block, Text ,  GatsbyImage ,GatsbyImageBackground} from 'components/atoms';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import styled from '@emotion/styled';

const featureMerchantBg = 'assets/merchant-feature-bg.png';
const featureUserBg = 'assets/feature-user-bg.jpg';
const pumpkinUser = 'assets/pumpkin-user.png';
const boy = 'assets/boy.png';
const cashierDesk = 'assets/cashier-desk.png';
const discountLabel = 'assets/discount-label.png';
const pumpkinMerchant = 'assets/pumpkin-merchant.png';
const desk = 'assets/desk.png';
const pinkGirl ='assets/pink-girl.png'; 

const FeatureText = {
  merchant: {
    notification: ['ส่ง Notifications'],
    userDatabase: ['ฐานข้อมูลลูกค้า'],
    campaign: ['แคมเปญและโปรโมชั่น'],
    collectAndRedeem: ['สะสมแสตมป์', 'แลกของรางวัล'],
    member: ['ระบบสมาชิก', 'และบัตรกำนัล'],
    poll: ['โพลล์และแบบสอบถาม']
  },
  user: {
    notification: ['รับ Notifications'],
    userDatabase: [],
    campaign: ['ไม่พลาดแคมเปญ', 'และโปรโมชั่น'],
    collectAndRedeem: ['สะสมแสตมป์', 'แลกของรางวัล'],
    member: ['สิทธิพิเศษ', 'เฉพาะคุณ'],
    poll: ['ทำแบบสอบถามรับของรางวัล']
  }
};

const MerchantFeatureDiv = styled('div')({
  position: 'relative',
  '.commentBox': {
    position: 'relative',
    background: '#88b7d5',
    border: '4px solid #c2e1f5',
    '&:after': {
      content: '""'
    }
  }
});

class PumpkinFeature extends React.Component {
  constructor() {
    super();
    this.state = { width: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  renderArrowIcon = (
    scrollValue,
    position,
    absoluteValue,
    absoluteTopValue
  ) => {
    return (
      <Block
        position={'absolute'}
        display={['block', 'none']}
        top={absoluteTopValue || '50%'}
        left={absoluteValue}
        zIndex={7}
        onClick={() =>
          (document.getElementById('cpDev1').scrollLeft = scrollValue)
        }>
        {position === 'left' ? (
          <ArrowLeft style={{ fontSize: 50, color: '#59c8fe' }} />
        ) : (
          <ArrowRight style={{ fontSize: 50, color: '#59c8fe' }} />
        )}
      </Block>
    );
  };

  renderCommentBox = (
    title,
    absoluteArrowValue,
    absoluteBottomValue,
    absoluteLeftValue
  ) => {
    return (
      <Block
        position={'absolute'}
        bottom={absoluteBottomValue}
        left={absoluteLeftValue}
        zIndex={8}
        display={title.length ? 'block' : 'none'}>
        <Block position={'relative'} width={'max-content'}>
          <Block
            p={['10px', 12]}
            background={'white'}
            borderRadius={title.length > 1 ? 20 : 40}>
            <Text
              fontWeight={'bold'}
              fontSize={[13, 15, 17]}
              textAlign={'center'}>
              {title[0]}
            </Text>
            {title[1] && (
              <Text
                fontWeight={'bold'}
                fontSize={[13, 15, 17]}
                textAlign={'center'}>
                {title[1]}
              </Text>
            )}
          </Block>
          <Block
            left={absoluteArrowValue}
            position={'absolute'}
            style={{
              width: 0,
              height: 0,
              borderStyle: 'solid',
              borderWidth: '15px 7.5px 0 7.5px',
              borderColor: 'white transparent transparent transparent'
            }}
          />
        </Block>
      </Block>
    );
  };

  render() {
    const { state } = this.props;
    const { width } = this.state;
    return (
      <MerchantFeatureDiv>
        <GatsbyImageBackground src={state === 'user' ? featureUserBg : featureMerchantBg}>
        <Block
          width={'100%'}
          height={[250, 350, 500]}
          backgroundSize={'cover'}>
          <Block
            maxWidth={['-webkit-fill-available', 700, 960, 1200]}
            mx={'auto'}
            height={[300, 600]}
            style={{
              overflowX: 'hidden',
              overflowY: 'hidden',
              scrollBehavior: 'smooth'
            }}
            id="cpDev1">
            <Block display={'inline-block'} width={'100%'}>
              <Block
                height={[250, 350, 500]}
                position={'relative'}
                width={[1500, '100%']}
                zIndex={2}
                display={['flex', 'block']}>
                <Block
                  position={['initial', 'absolute']}
                  left={[0, 1, 61]}
                  bottom={-32}
                  width={[width, 'auto']}>
                  <Block position={'relative'}>
                    <GatsbyImage
                      src={pumpkinUser}
                      position={'absolute'}
                      bottom={[-300, -31, -26]}
                      left={[56, 42, 59]}
                      width={[85, 90, 135]}
                      zIndex={5}
                    />
                    {this.renderCommentBox(
                      FeatureText[state]['notification'],
                      [52, 63],
                      [-135, 145, 221],
                      [28, 10, 45]
                    )}
                    <GatsbyImage
                      src={cashierDesk}
                      position={['absolute', 'initial']}
                      bottom={-273}
                      left={'5%'}
                      width={[250, 270, 420]}
                      zIndex={4}
                    />
                    {this.renderCommentBox(
                      FeatureText[state]['userDatabase'],
                      [53, 23, 53],
                      [-113, 168, 249],
                      [166, 153, 220]
                    )}
                    {this.renderArrowIcon(width, 'right', '87%', 125)}
                  </Block>
                </Block>
                <Block
                  position={['initial', 'absolute']}
                  left={[width + 50, 235, 350]}
                  bottom={-45}
                  width={[width, 'auto']}>
                  <Block position={'relative'}>
                    <GatsbyImage
                      src={discountLabel}
                      position={['absolute', 'initial']}
                      bottom={-300}
                      left={'5%'}
                      width={[55, 60, 95]}
                      zIndex={5}
                    />
                    {this.renderCommentBox(
                      FeatureText[state]['campaign'],
                      [53, 63, 53],
                      [-233, 73, 106],
                      ['0%', -39, -14]
                    )}
                    <GatsbyImage
                      src={boy}
                      position={'absolute'}
                      bottom={[-280, -10]}
                      left={['19%', 93, 165]}
                      width={[145, 145, 180]}
                      zIndex={5}
                    />
                    {this.renderCommentBox(
                      FeatureText[state]['collectAndRedeem'],
                      [26, 32, 67],
                      [-133, 145, 196],
                      ['18%', 68, 144]
                    )}
                    <GatsbyImage
                      src={desk}
                      position={'absolute'}
                      maxWidth={'fit-content'}
                      bottom={[-276, 1]}
                      left={['48%', 183, 300]}
                      width={[140, 140, 170]}
                      zIndex={4}
                    />
                    {this.renderCommentBox(
                      FeatureText[state]['member'],
                      18,
                      [-133, 138, 170],
                      ['55%', 218, 338]
                    )}
                    {this.renderArrowIcon(0, 'left', '-2%', 125)}
                    {this.renderArrowIcon(width * 2, 'right', '87%', 125)}
                  </Block>
                </Block>
                <Block
                  position={['initial', 'absolute']}
                  left={[width * 2 + 70, 550, 865]}
                  bottom={-45}
                  width={[width, 'auto']}>
                  <Block position={'relative'}>
                    <GatsbyImage
                      src={pumpkinMerchant}
                      width={[105, 75, 120]}
                      position={['absolute', 'initial']}
                      left={'17%'}
                      bottom={-280}
                      zIndex={5}
                    />
                    <GatsbyImage
                      src={pinkGirl}
                      position={'absolute'}
                      bottom={[-280, -2]}
                      left={['52%', 75, 126]}
                      width={[102, 75, 120]}
                      zIndex={4}
                    />
                    {this.renderCommentBox(
                      FeatureText[state]['poll'],
                      [53, 69],
                      [-100, 159, 230],
                      ['33%', 3, 51]
                    )}
                    {this.renderArrowIcon(width, 'left', '2%', 125)}
                  </Block>
                </Block>
                {/* 
                {this.renderCommentBox(
                  FeatureText[state]['poll'],
                  [53, 69],
                  [177, 133, 216],
                  [866, 548, 915]
                )} */}
                {/* <div
                  style={{
                    width: 0,
                    height: 0,
                    borderStyle: 'solid',
                    borderWidth: '15px 7.5px 0 7.5px',
                    borderColor: '#007bff transparent transparent transparent'
                  }}
                /> */}
              </Block>
            </Block>
          </Block>
        </Block>
        <Block
          background={state === 'user' ? 'white' : '#fff8df'}
          width={'100%'}
          height={[40, 40, 60]}
        />
        </GatsbyImageBackground>
      </MerchantFeatureDiv>
    );
  }
}

export default PumpkinFeature;

import React from 'react';
import { MerchantPricePlanTable } from 'components/tables';
import { Text, Block, Button } from 'components/atoms';
import Grid from '@material-ui/core/Grid';
import Done from '@material-ui/icons/Done';
import { Link } from 'react-scroll'

// REDUX
import { connect } from 'react-redux';
import { selectGetPlansStatus, selectPlans } from 'modules/package/selectors';
import { getPlans } from 'modules/package/actions';
import { bindActionCreators } from 'redux';

class MerchantPricePlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const { plans } = this.props;
    if (!plans || !plans.length) {
      this.props.getPlans().then(result => console.log('result', result));
    }
  }

  enterpriseInformationBox = information => {
    return (
      <Block
        width={'100%'}
        display={'flex'}
        alignItems={['flex-start', 'flex-start', 'center']}>
        <Block
          background={'#91dc5a'}
          width={19}
          height={19}
          borderRadius={'50%'}
          mr={15}>
          <Done style={{ width: 19, height: 19, color: 'white' }} />
        </Block>
        <Text color={'#4a4a4a'} fontSize={18}>
          {information}
        </Text>
      </Block>
    );
  };
  render() {
    const { plans } = this.props;
    return (
      <Block
        background={'linear-gradient(179deg, #fff8df, #fff2c2 37%, #ffe18f)'}>
        <Block
          pt={[30]}
          pb={[30]}
          pr={[30, 50]}
          pl={[30, 50]}
          maxWidth={['initial', 700, 960, 1200]}
          mx={'auto'}>
          <Text
            fontSize={[30, 40]}
            textAlign={'center'}
            fontWeight={'bold'}
            color={'#893f18'}
            letterSpacing={'1.5px'}
            mb={[25, 50]}>
            PRICING PLAN
          </Text>
          {plans.length > 0 && (
            <MerchantPricePlanTable
              plans={plans}
              showOnLandingPage
              autoSetActivePackage
              activePackage={plans.find(plan => plan.price === 599).id}
            />
          )}
          <Block display={['none', 'block']} mt={55}>
            <Grid container spacing={24} justify="flex-start">
              <Grid item xs={3}>
                <Text fontSize={28} fontWeight={'bold'}>
                  Enterprise Solutions
                </Text>
              </Grid>
              <Grid item xs={3}>
                {this.enterpriseInformationBox('สะสมแต้มผ่านเบอร์มือถือลูกค้า')}
                {this.enterpriseInformationBox('เพิ่มลูกค้าเองในระบบ')}
              </Grid>
              <Grid item xs={3}>
                {this.enterpriseInformationBox(
                  'แจ้งข่าวสารและโปรโมชั่นผ่าน SMS'
                )}
                {this.enterpriseInformationBox('เชื่อมต่อกับระบบ POS')}
              </Grid>
              <Grid item xs={3}>
                {this.enterpriseInformationBox('แอพพลิเคชั่น CRM ของคุณเอง')}
                {this.enterpriseInformationBox('ปรับแต่ง Features ตามต้องการ')}
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Link
                to="MerchantContractForm"
                smooth={true}
                offset={50}
                duration={500}>
                <Button.Contain
                  color={'primary'}
                  rounded
                  mt={50}
                  mr={'auto'}
                  ml={'auto'}
                  style={{ fontSize: 24, width: 200 }}>
                  ติดต่อเรา
                </Button.Contain>
              </Link>
            </Grid>
          </Block>
        </Block>
      </Block>
    );
  }
}

export default connect(
  state => ({
    getPlansStatus: selectGetPlansStatus(state),
    plans: selectPlans(state)
  }),
  dispatch =>
    bindActionCreators(
      {
        getPlans
      },
      dispatch
    )
)(MerchantPricePlan);

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { auth } from 'appFirebase/config';
import { AuthConsumer } from 'contexts/authContext';

import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { Text, Image } from 'components/atoms';

import { VERSION, ADD_LINE_LINK, LINE_LOGO } from 'helpers/constants';

import pumpkinLabel from 'assets/logo/pumpkin-label.png';
import { ClassNames } from '@emotion/core';

const StyledDrawer = styled(Drawer)(
  {
    '.navigator__version': {
      position: 'absolute',
      bottom: 0,
      padding: 0
    },
    '.navigator__logo-item': {
      height: 80,
      background:
        'linear-gradient(232deg, rgba(238,182,73,1) 0%, rgba(244,107,69,1) 100%)'
    },
    '.navigator__logo-label': {
      maxWidth: 150
    }
  },
  ({ theme }) => ({
    '.navigator__list-item--active': {
      backgroundColor: '#fff6ec',
      '.MuiListItemIcon-root': {
        color: theme.get('colors.primary')
      },
      '.pumpkin-text': {
        color: theme.get('colors.primary'),
        fontWeight: 500
      }
    }
  })
);

const Navigator = ({ items, open, onClose, me }) => {
  const closeAfter = fn => () => {
    fn();
    onClose();
  };
  return (
    <StyledDrawer className={'navigator'} open={open} onClose={onClose}>
      <ListItem className={'navigator__logo-item'}>
        <Image
          className={'navigator__logo-label'}
          src={pumpkinLabel}
          alt={'pumpkinLabel'}
        />
      </ListItem>
      <List>
        {items.map(({ id, label, active, icon, toPath }) => (
          <ClassNames key={id}>
            {({ cx }) => (
              <ListItem
                key={id}
                className={cx(active && 'navigator__list-item--active')}
                button
                onClick={closeAfter(toPath)}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText disableTypography>
                  <Text>{label}</Text>
                </ListItemText>
              </ListItem>
            )}
          </ClassNames>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem component={'a'} button href={ADD_LINE_LINK} target={'_blank'}>
          <ListItemIcon>
            <Image
              src={LINE_LOGO}
              width={40}
              height={40}
              borderRadius={'4px'}
            />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Text fontWeight={500}>Add Friend</Text>
            <Text color={'grey'} fontSize={18} mt={'-4px'}>
              เช็คแต้มได้ทุกที่ ทุกเวลา
            </Text>
          </ListItemText>
        </ListItem>
      </List>
      <Divider />
      {me && (
        <React.Fragment>
          <List>
            <ListItem button onClick={() => auth.signOut().then(onClose)}>
              <ListItemIcon>
                <ExitToApp />
              </ListItemIcon>
              <ListItemText disableTypography>
                <Text>Log out</Text>
              </ListItemText>
            </ListItem>
          </List>
          <Divider />
        </React.Fragment>
      )}
      <List className={'navigator__version'}>
        <ListItem>
          <ListItemText disableTypography>
            <Text color={'lightGrey'}>v{VERSION}</Text>
          </ListItemText>
        </ListItem>
      </List>
    </StyledDrawer>
  );
};

Navigator.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default Navigator;

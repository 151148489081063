import React from 'react';
import { Block, Text, Button } from 'components/atoms';
import { Link } from 'gatsby';
import { Link as Scroll } from 'react-scroll';
import { css } from '@emotion/core';
import ReactMarkdown from 'react-markdown';
// Theme
import { withTheme as withEmotion } from 'emotion-theming';
import { ContractForm } from 'components/molecules';
import get from 'lodash/get';

const Article = ({
  article,
  TextColor,
  TextAlign = null,
  locale = 'en',
  theme
}) => {
  const primary = get(theme, 'palette.primary.main');
  const {
    // Title_th,
    // Title_en,
    // Subtitle_th,
    // Subtitle_en,
    //buttonText_th,
    Form,
    buttonText_en,
    Action,
    Width,
    Width_Mobile
  } = article;
  const renderButton = () => {
    if (Action) {
      const externalPaths = ['http', 'mailto', 'tel'];
      const external = externalPaths.some(externalPath =>
        Action.startsWith(externalPath)
      );
      if (external) {
        return (
          <a
            href={Action}
            style={{ textDecoration: 'none' }}
            rel="noopener noreferrer">
            <Button.Contain
              rounded
              style={{ color: 'white', background: primary }}>
              {article[`buttonText_${locale}`]}
            </Button.Contain>
          </a>
        );
      }
      if (Action.startsWith('#')) {
        return (
          <Block
            css={css`
              cursor: pointer;
            `}>
            <Scroll to={Action.slice(1)} smooth={true} duration={500}>
              <Button.Contain
                rounded
                style={{ color: 'white', background: primary }}>
                {article[`buttonText_${locale}`]}
              </Button.Contain>
            </Scroll>
          </Block>
        );
      }
      return (
        <Link to={Action} style={{ textDecoration: 'none' }}>
          <Button.Contain
            rounded
            style={{ color: 'white', background: primary }}>
            {article[`buttonText_${locale}`]}
          </Button.Contain>
        </Link>
      );
    }
    return (
      <Button.Contain
        rounded
        style={{ color: 'white', background: primary }}
        onClick={e => {
          e.stopPropagation();
        }}>
        {article[`buttonText_${locale}`]}
      </Button.Contain>
    );
  };
  return (
    <Block
      width={[Width_Mobile || Width || 'auto', Width || 'auto']}
      p={[20, 0]}>
      <Text color={TextColor} component={'span'}>
        <ReactMarkdown source={article[`Title_${locale}`]} />
      </Text>
      <Text color={TextColor} variant={'subtitle1'} component={'span'}>
        <ReactMarkdown source={article[`Subtitle_${locale}`]} />
      </Text>
      {buttonText_en && (
        <Block mb={'1.5em'}>{buttonText_en && renderButton()}</Block>
      )}
      {Form && <ContractForm />}
    </Block>
  );
};

export default withEmotion(Article);

import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import { cx, css, ClassNames } from '@emotion/core';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Button, Block } from 'components/atoms';

const createCommonProps = ({ name, errors, touched }) => ({
  name,
  error: Boolean(touched[name] && errors[name]),
  helperText: touched[name] && errors[name] ? errors[name] : '',
  label: name,
  margin: 'normal',
  variant: 'outlined',
  fullWidth: true,
  required: true
});

const validationSchema = Yup.object().shape({
  brandname: Yup.string().required('กรุณากรอกข้อมูลให้ถูกต้อง'),
  type: Yup.string().required('กรุณากรอกข้อมูลให้ถูกต้อง'),
  email: Yup.string().required('กรุณากรอกข้อมูลให้ถูกต้อง')
});

const inputCss = css({
  label: {
    fontFamily: 'thai-sans-neue',
    fontSize: 18
  },
  '.MuiSelect-select.MuiSelect-outlined': {
    padding: '18.5px 14px'
  }
});

const ContactForm = styled(
  ({ initialValues, onSubmit, buttonClassName, loading }) => {
    const fields = [
      {
        name: 'brandname'
      },
      {
        name: 'type',
        select: true,
        options: [
          'Cafe',
          'Salon & Spa',
          'Restaurant',
          'Grab N Go Beverage',
          'Sport Club',
          'Others'
        ]
      },
      {
        name: 'email'
      }
    ];
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        render={({
                   values,
                   errors,
                   touched,
                   handleChange,
                   handleBlur,
                   handleSubmit,
                   isSubmitting,
                   className
                 }) => (
          <ClassNames>
            {({ cx }) => (
              <form
                onSubmit={handleSubmit}
                className={cx('contract-form', className)}>
                {fields.map(({ name, options = [], ...rest }) => (
                  <TextField
                    key={name}
                    className={inputCss}
                    {...createCommonProps({
                      name,
                      errors,
                      touched
                    })}
                    {...rest}
                    value={values[name] || ''}
                    onChange={event => {
                      handleChange(event);
                    }}
                    onBlur={handleBlur}>
                    {options.map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                ))}
                <FormControl margin={'normal'} fullWidth>
                  <Block textAlign={'end'}>
                    <Button
                      type={'submit'}
                      variant={'contained'}
                      color={'primary'}
                      size={'large'}
                      loading={loading}
                      className={buttonClassName}
                      rounded
                      thai>
                      สมัครเลย
                    </Button>
                  </Block>
                </FormControl>
              </form>
            )}
          </ClassNames>
        )}
      />
    );
  }
)(() => ({
  '.MuiSelect-select MuiSelect-selectMenu MuiSelect-outlined MuiInputBase-input MuiOutlinedInput-input': {
    paddingTop: 18,
    paddingBottom: 18
  }
}));

ContactForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.shape({}),
  buttonClassName: PropTypes.string
};
ContactForm.defaultProps = {
  initialValues: {},
  onSubmit: values => alert(JSON.stringify(values, null, 2)),
  buttonClassName: ''
};

export default ContactForm;

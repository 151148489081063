import get from 'lodash/get';

const selectAuthState = state => get(state, 'auth');
export const selectAuthData = state => selectAuthState(state).data;
export const selectGetAuthStatus = state =>
  selectAuthState(state).getProfileStatus;
export const selectObserveAuthStatus = state =>
  selectAuthState(state).observeAuth;
export const selectRegisterFacebookStatus = state =>
  selectAuthState(state).registerFacebook;

import CollectionFactory from './CollectionFactory'

// CREATE OPT
// insertOne
// insertMany

// READ OPT
// find(queries)

// UPDATE OPT
// updateOne
// updateMany
// replaceOne

// DELETE OPT
// deleteOne()
// deleteMany()

const FireStoreFactory = (fireApp, settings) => {
  const FireStore = fireApp.firestore()
  if (settings) {
    FireStore.settings(settings)
  }

  return {
    makeCollection(collectionName) {
      return CollectionFactory(FireStore.collection(collectionName))
    },
  }
}

export default FireStoreFactory

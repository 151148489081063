import withWidth from '@material-ui/core/withWidth/withWidth';
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
// import { FormattedMessage } from 'react-intl';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import Table from '@material-ui/core/Table/Table';
import TableBody from '@material-ui/core/TableBody/TableBody';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import { isWidthUp } from '@material-ui/core/withWidth';
import { Block, Image, Text } from 'components/atoms';

import Done from '@material-ui/icons/Done';
import Clear from '@material-ui/icons/Clear';
import { Button } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

const breakpoints = createBreakpoints({});

const Root = styled('div')({
  alignItems: 'flex-start',
  '.firstColumnBox': {
    display: 'none',
    [breakpoints.up('sm')]: {
      display: 'table-cell'
    },
    width: '25%'
  },
  '.hideBottomRow': {
    borderBottom: 'hidden'
  },
  '.quota-row': {
    [breakpoints.down('xs')]: {
      borderBottom: 'hidden'
    }
  },
  '.quota-mobile-first-column': {
    display: 'table-row',
    [breakpoints.up('sm')]: {
      display: 'none'
    },
    borderBottom: 'hidden'
  },
  '.quota-text': {
    [breakpoints.down('xs')]: {
      borderRight: '1px solid grey'
    }
  },
  '.footer-div': {
    textAlign: 'center',
    width: '100%'
  },
  '.package-button': {
    width: '100%',
    height: 48,
    [breakpoints.up('sm')]: {
      width: 300
    }
  },
  '.textCenter': {
    textAlign: 'center'
  },
  '.image-column': {
    paddingRight: 0,
    paddingLeft: 0,
    textAlign: '-webkit-center'
  },
  '.header-text': {
    fontSize: 19,
    [breakpoints.up('sm')]: {
      fontSize: 16
    }
  }
});

const PricePlanTable = ({
  plans,
  width,
  setActivePackage,
  activePackage = plans.find(plan => plan.price === 599).id,
  showOnLandingPage = false
}) => {
  const renderPackageImage = () =>
    plans.map(item => (
      <TableCell
        key={item.id}
        onClick={() => (!showOnLandingPage ? setActivePackage(item.id) : null)}
        className={'image-column'}
        style={{ cursor: !showOnLandingPage ? 'pointer' : 'default' }}>
        {item.price === 599 && (
          <Chip
            color="primary"
            label="Recommend"
            style={{ fontSize: isWidthUp('sm', width) ? 14 : 11.5 }}
          />
        )}
        <Image
          src={
            activePackage === item.id ? item.imageUrl : item.disabledImageUrl
          }
          style={{ width: isWidthUp('sm', width) ? 90 : 65, minWidth: 90 }}
        />
      </TableCell>
    ));
  const renderQuotaHeaderMobile = header => (
    <TableRow className={'quota-mobile-first-column'}>
      <TableCell colSpan="5">
        <Block mt={10} mb={10}>
          <Text textAlign={'center'} fontSize={16} fontWeight={500}>
            {header}
          </Text>
        </Block>
      </TableCell>
    </TableRow>
  );
  const renderPricePackage = () =>
    plans.map(item => (
      <TableCell key={item.id}>
        <Text
          textAlign={'center'}
          className={'quota-text'}
          color={activePackage === item.id ? 'primary' : 'lightGrey'}
          fontSize={[15, 15, 18]}>
          {item.price === 0 ? 'ฟรี' : `${item.price} บาท`}
        </Text>
      </TableCell>
    ));
  const renderQuota = quota => {
    if (quota === 'Community') return 'Community';
    if (quota === 'Direct Message') return 'แชทโดยตรง';
    return quota;
  };

  const renderProductQuota = productType =>
    plans.map((item, index) => (
      <TableCell className={'textCenter'} key={item.id}>
        {item.products[productType].quota !== 0 ? (
          <Text
            color={activePackage === item.id ? 'primary' : 'lightGrey'}
            textAlign={'center'}
            className={index !== plans.length - 1 ? 'quota-text' : ''}
            fontSize={[15, 15, 18]}
            fontWeight={500}>
            {renderQuota(item.products[productType].quota)}
          </Text>
        ) : (
          <Block className={index !== plans.length - 1 ? 'quota-text' : ''}>
            <Clear
              style={{
                color: activePackage === item.id ? '#f89202' : '#b5b5b5'
              }}
            />
          </Block>
        )}
      </TableCell>
    ));
  const renderCheckColumn = () =>
    plans.map((item, index) => (
      <TableCell key={item.id} className={'textCenter'}>
        <Done
          style={{
            color: activePackage === item.id ? '#f89202' : '#b5b5b5',
            width: '100%'
          }}
          className={index !== plans.length - 1 ? 'quota-text' : ''}
        />
      </TableCell>
    ));
  const renderBillingRow = (firstColumnType, subText, productType) => (
    <TableRow className={'quota-row'}>
      <TableCell className={'firstColumnBox'}>
        <Text>{firstColumnType}</Text>
        {subText && <Text>{subText}</Text>}
      </TableCell>
      {productType === 'collectRedeemPoints' ||
      productType === 'customerStatistic'
        ? renderCheckColumn()
        : renderProductQuota(productType)}
    </TableRow>
  );

  const renderSelectButton = () => {
    return (
      <TableRow className={'hideBottomRow'}>
        <TableCell className={'firstColumnBox'} />
        {plans.map(plan => (
          <TableCell className={'textCenter'} key={plan.id}>
            <Button
              color={'primary'}
              variant={activePackage === plan.id ? 'contained' : 'outlined'}
              key={plan.id}
              onClick={() => setActivePackage(plan.id)}>
              select
            </Button>
          </TableCell>
        ))}
      </TableRow>
    );
  };
  return (
    <Root>
      <Table padding={isWidthUp('sm', width) ? 'dense' : 'none'}>
        <TableHead>
          <TableRow style={{ borderStyle: 'hidden', verticalAlign: 'bottom' }}>
            <TableCell className={'firstColumnBox'} />
            {renderPackageImage()}
          </TableRow>
          {renderQuotaHeaderMobile('monthlyPrice')}
          <TableRow className={'quota-row'}>
            <TableCell className={'firstColumnBox'}>
              <Text>ค่าใช้จ่ายรายเดือน</Text>
            </TableCell>
            {renderPricePackage()}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={'hideBottomRow'}>
            <TableCell colSpan="5">
              <Block mt={20} mb={20}>
                <Text
                  fontWeight={'bold'}
                  textAlign={isWidthUp('sm', width) ? 'left' : 'center'}
                  fontSize={isWidthUp('sm', width) ? 17 : 20}>
                  ฟีเจอร์ทั่วไป
                </Text>
              </Block>
            </TableCell>
          </TableRow>
          {renderQuotaHeaderMobile('collect&RedeemPoints')}
          {renderBillingRow('collect&RedeemPoints', '', 'collectRedeemPoints')}
          {renderQuotaHeaderMobile('maximumUniqueUsers')}
          {renderBillingRow('maximumUniqueUsers', '', 'uniqueUser')}
          {renderQuotaHeaderMobile('customerStatistics')}
          {renderBillingRow('customerStatistics', '', 'customerStatistic')}
          {renderQuotaHeaderMobile('maximumBranches')}
          {renderBillingRow('maximumBranches', '', 'branch')}
          {renderQuotaHeaderMobile('maximumAdministrators')}
          {renderBillingRow('maximumAdministrators', '', 'admin')}
          <TableRow className={'hideBottomRow'}>
            <TableCell colSpan="5">
              <Block mt={20} mb={20}>
                <Text
                  fontWeight={'bold'}
                  textAlign={isWidthUp('sm', width) ? 'left' : 'center'}
                  fontSize={isWidthUp('sm', width) ? 17 : 20}>
                  ฟีเจอร์พิเศษ
                </Text>
              </Block>
            </TableCell>
          </TableRow>
          {renderQuotaHeaderMobile('membershipPerMonth')}
          {renderBillingRow('membership', 'memberRulesSubText', 'member')}
          {renderQuotaHeaderMobile('voucherPerMonth')}
          {renderBillingRow('voucher', 'voucherRulesSubText', 'voucher')}
          {renderQuotaHeaderMobile('directMessagePerMonth')}
          {renderBillingRow(
            'directMessage',
            'directMessageRulesSubText',
            'directMessage'
          )}
          {renderQuotaHeaderMobile('eCouponPerMonth')}
          {renderBillingRow('eCoupon', 'eCouponRulesSubText', 'campaign')}
          {renderQuotaHeaderMobile('pollSurveyPerMonth')}
          {renderBillingRow('pollSurvey', 'pollRulesSubText', 'survey')}
          {renderQuotaHeaderMobile('support')}
          {renderBillingRow('support', '', 'support')}
          {!showOnLandingPage && renderSelectButton()}
        </TableBody>
      </Table>
    </Root>
  );
};

PricePlanTable.propTypes = {
  plans: PropTypes.arrayOf(PropTypes.shape({})),
  width: PropTypes.string.isRequired,
  activePackage: PropTypes.string,
  setActivePackage: PropTypes.func.isRequired,
  showOnLandingPage: PropTypes.bool
};
PricePlanTable.defaultProps = {
  plans: [],
  activePackage: '',
  showOnLandingPage: false
};

export default withWidth()(PricePlanTable);

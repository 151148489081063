import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ClassNames } from '@emotion/core';

//COMPONENTS
import Warning from '@material-ui/icons/Warning';
import { Button, Block, Text } from 'components/atoms';
import Modal from './Modal';
// import styled from '@emotion/styled'

const DeleteModal = styled(
  ({
     header,
     open,
     loading,
     onClose,
     onSubmit,
     className,
     minWidth,
     contentMessage
   }) => (
    <ClassNames>
      {({ cx }) => (
        <Modal
          header={header}
          className={cx('modal-cancle', className)}
          open={open}
          title={'ยืนยันการลบ'}
          minWidth={minWidth}
          content={
            <div className={'warning-content'}>
              <Block.Middle className={'warning-text'}>
                <Warning className={'warning-icon'}/>
                <span className={'text-span'}>
              <Text danger>{contentMessage}</Text>
            </span>
              </Block.Middle>
            </div>
          }
          actions={[
            <Button onClick={onClose}>ยกเลิก</Button>,
            <Button.Delete autoFocus onClick={onSubmit} loading={loading}>
              ยืนยัน
            </Button.Delete>
          ]}
          onClose={onClose}
        />)}
    </ClassNames>
  )
)(() => ({
  '.warning-content': {
    background: '#FBE9E7',
    padding: 24
  },
  '.warning-text': {
    color: '#D50000'
  },
  '.warning-icon': {
    color: '#D50000',
    paddingRight: 24
  },
  '.text-span': {
    marginLeft: 8,
    verticalAlign: 'text-bottom'
  },
  '.submit-button': {
    marginRight: 11,
    marginBottom: 6
  }
}));

DeleteModal.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  name: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};
DeleteModal.defaultProps = {
  open: false,
  loading: false,
  onClose: () => {
  },
  onSubmit: () => {
  },
  name: ''
};

export default DeleteModal;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import TextField from '@material-ui/core/TextField';
import { Button, Block, Text } from 'components/atoms';
import CloudApi from 'api/cloudApi';

const createCommonProps = ({ name, errors, touched }) => ({
  name,
  error: Boolean(touched[name] && errors[name]),
  helperText: touched[name] && errors[name] ? errors[name] : '',
  label: name,
  margin: 'normal',
  variant: 'outlined',
  fullWidth: true,
  required: true
});

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('กรุณากรอกอีเมลล์ให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลให้ถูกต้อง')
});

const inputCss = css({
  background: 'white',
  label: {
    fontFamily: 'thai-sans-neue',
    fontSize: 18
  },
  '.MuiSelect-select.MuiSelect-outlined': {
    padding: '18.5px 14px',
    border: '1px red solid'
  },
  '.MuiFormHelperText-root.MuiFormHelperText-error': {
    background: '#fff4e9',
    paddingTop: 8,
    paddingLeft: 12,
    paddingRight: 12,
    margin: 0
  },
  '.MuiOutlinedInput-root': {
    borderRadius: 30
  }
});

const ContactForm = styled(
  ({ initialValues, onSubmit = () => {}, buttonClassName }) => {
    const fields = [
      {
        name: 'email',
        label: 'email'
      }
    ];

    const [loading, setLoading] = useState(false);
    const [finishSending, setFinishSending] = useState(false);

    const sendEmail = (values, { resetForm }) => {
      setLoading(true);
      return CloudApi.addContractToGoogleSheet(values).then(() => {
        resetForm();
        setFinishSending(true);
        setLoading(false);
      });
    };

    return (
      <Block
        css={{
          '.MuiPrivateNotchedOutline-root': {
            borderRadius: 35
          },
          '.MuiInputLabel-outlined': {
            paddingLeft: 14
          }
        }}>
        <Formik
          initialValues={initialValues}
          onSubmit={sendEmail}
          validationSchema={validationSchema}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            className
          }) => (
            <form onSubmit={handleSubmit}>
              {fields.map(({ name, label, options = [], ...rest }) => (
                <TextField
                  key={name}
                  className={inputCss}
                  {...createCommonProps({
                    name,
                    errors,
                    touched
                  })}
                  label={label}
                  value={values[name] || ''}
                  onChange={event => {
                    if (finishSending) {
                      setFinishSending(false);
                    }
                    handleChange(event);
                  }}
                  onBlur={handleBlur}
                />
              ))}
              {finishSending && (
                <Text color={'#38D203'} ml={15}>
                  Successful sending
                </Text>
              )}
              <Block textAlign={'right'} mt={[10, 15]}>
                <Button
                  type={'submit'}
                  variant={'contained'}
                  color={'primary'}
                  loading={loading}
                  className={buttonClassName}
                  rounded
                  style={{ width: 150 }}>
                  send
                </Button>
              </Block>
            </form>
          )}
        />
      </Block>
    );
  }
)(() => ({
  '.MuiSelect-select MuiSelect-selectMenu MuiSelect-outlined MuiInputBase-input MuiOutlinedInput-input': {
    paddingTop: 18,
    paddingBottom: 18
  }
}));

ContactForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.shape({}),
  buttonClassName: PropTypes.string
};
ContactForm.defaultProps = {
  initialValues: {},
  onSubmit: values => alert(JSON.stringify(values, null, 2)),
  buttonClassName: ''
};

export default ContactForm;

import React from 'react';
import {
  Block,
  Button,
  GatsbyImage,
  GatsbyImageBackground
} from 'components/atoms';
// import Pumpkin from 'assets/pumpkin-in-header.png';
// import Vs from 'assets/vs.png';
// import User from 'assets/user.png';
import { css, jsx } from '@emotion/core';
import { navigate } from 'gatsby';
import { Link } from 'react-scroll';

const UserHeaderBg = 'assets/user-header-BG.jpg';
const WhichTeamLogo = 'assets/which-team-logo.png';
const PumpkinLogo = 'assets/pumpkin-logo.png';
const CashierDeskInHeader = 'assets/cashier-desk-in-Header.png';
const Desk = 'assets/desk-in-user-header.png';
const OrangeCard = 'assets/orange-card.png';
const Star = 'assets/star.png';

const base = css`
  .MuiButton-root {
    width: 200px;
  }
  @media screen and (max-width: 600px) {
    .MuiButton-root {
      width: 100px;
    }
  }
`;
const UserHeader = () => {
  return (
    <GatsbyImageBackground
      src={UserHeaderBg}
      backgroundStyle={{
        backgroundSize: 'cover',
        backgroundPosition: 'bottom'
      }}>
      <Block
        css={[base]}
        p={['50px 0px 0px', '30px 80px']}
        position={'relative'}>
        <Block
          css={{
            overflow: 'hidden'
          }}
          maxWidth={['inherit', 700, 960, 1200]}
          m={'auto'}
          position={'relative'}>
          <GatsbyImage
            src={CashierDeskInHeader}
            position={'absolute'}
            bottom={10}
            width={[180, 220, 350]}
            left={[-80, 0]}
            zIndex={1}
            display={'block'}
          />
          <GatsbyImage
            src={Star}
            position={'absolute'}
            bottom={20}
            width={[30, 50]}
            top={165}
            imageStyle={{ objectFit: 'contain' }}
            left={[10, 160]}
            display={'block'}
          />
          <GatsbyImage
            src={Star}
            position={'absolute'}
            top={[90, 35]}
            left={[-12, 0]}
            width={[45, 90]}
            display={['block']}
          />
          <Block
            position={'absolute'}
            bottom={10}
            right={[-70, 0]}
            zIndex={1}
            width={[150, 150, 'auto']}
            display={'block'}>
            <GatsbyImage
              src={OrangeCard}
              width={[50, 80]}
              mb={[45, 15]}
              m={'auto'}
            />
            <GatsbyImage src={Desk} width={[250, 265]} />
          </Block>
          <GatsbyImage src={WhichTeamLogo} m={'auto'} width={[200, 240]} />
          <GatsbyImage src={PumpkinLogo} m={'auto'} width={[220, 400]} />
          <Block
            display={'flex'}
            justifyContent={'center'}
            mt={50}
            position={'relative'}
            zIndex={2}>
            <Block
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}>
              <Button.Contain
                rounded
                style={{ color: 'white', background: '#ffa019' }}
                onClick={() => navigate('/merchant')}>
                ร้านค้า
              </Button.Contain>
              <GatsbyImage
                src={'assets/pumpkin-in-header.png'}
                width={[100, 140]}
              />
            </Block>
            <GatsbyImage
              src={'assets/vs.png'}
              width={[50, 100]}
              imageStyle={{ objectFit: 'contain' }}
            />

            {/* <Image src={Vs} objectFit={'contain'} width={[50, 'auto']} /> */}
            <Block
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}>
              <Link
                to="privilegeSection"
                smooth={true}
                offset={50}
                duration={500}>
                <Button.Contain
                  rounded
                  mb={10}
                  style={{ color: 'white', background: '#64b4ff' }}
                  css={base}
                  color={'white'}>
                  นักสะสม
                </Button.Contain>
              </Link>
              <GatsbyImage src={'assets/user.png'} width={[77, 110]} />
              {/* <Image src={User} objectFit={'contain'} width={[77, 110]} /> */}
            </Block>
          </Block>
        </Block>
      </Block>
    </GatsbyImageBackground>
  );
};

export default UserHeader;

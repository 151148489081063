import React from 'react';
import styled from '@emotion/styled';

import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Map from '@material-ui/icons/Map';
import Phone from '@material-ui/icons/Phone';
import Grid from '@material-ui/core/Grid';

const StyledListItem = styled(ListItem)({
  '&.MuiListItem-root': {
    backgroundColor: '#ffffff',
    paddingRight: 16,
    paddingLeft: 16
  },
  '& .MuiAvatar-root': {
    width: 60,
    height: 60
  },
  '& .MuiListItemText-root': {
    paddingRight: 0
  },
  '& .MuiListItemText-primary': {
    fontWeight: 'bold'
  },
  '& .MuiListItemText-secondary': {
    lineHeight: 1
  }
});

class BrandList extends React.Component {
  render() {
    const { logo, name, location, phoneNum, mapLink, onClick } = this.props;
    return (
      <StyledListItem button onClick={onClick}>
        <ListItemAvatar>
          <Avatar src={logo} />
        </ListItemAvatar>
        <ListItemText primary={name} secondary={location} />
        <IconButton
          component={'a'}
          disabled={!mapLink}
          href={mapLink}
          target={'_blank'}
          onClick={e => e.stopPropagation()}>
          <Map />
        </IconButton>
        <IconButton
          disabled={!phoneNum}
          component={'a'}
          href={`tel://+66${phoneNum}`}
          onClick={e => e.stopPropagation()}>
          <Phone />
        </IconButton>
      </StyledListItem>
    );
  }
}

const StyledList = styled(List)({
  '& .MuiListItem-root:not(:last-child)': {
    marginBottom: 8
  }
});

const BrandListGroup = ({ brands, liff, userId }) => (
  <StyledList>
    <Grid container spacing={24}>
      {brands.map(brand => (
        <Grid item xs={12} md={6} key={brand.id}>
          <BrandList
            {...brand}
            onClick={e => {
              e.preventDefault();
              if (userId) {
                liff
                  .sendMessages([
                    {
                      type: 'text',
                      text: `ร้าน ${brand.name}`
                    }
                  ])
                  .then(() => {
                    liff.closeWindow();
                  });
              }
            }}
          />
        </Grid>
      ))}
    </Grid>
  </StyledList>
);

BrandList.Group = BrandListGroup;

export default BrandList;

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Block, Image, Text, GatsbyImage } from 'components/atoms';

const CollectPointBenefit = 'assets/collect-point-benefit.png';
const NotificationBenefit = 'assets/notification-benefit.png';
const RewardBenefit = 'assets/reward-benefit.png';

const BenefitBox = (title, description, image) => {
  return (
    <Block>
      <GatsbyImage src={image} width={[180, 220]} m={'auto'} />
      <Text textAlign={'center'} fontWeight={'bold'} fontSize={[20, 20, 25]}>
        {title}
      </Text>
      <Block pl={30} pr={30}>
        <Text textAlign={'center'} fontSize={[16, 18, 20]}>
          {description}
        </Text>
      </Block>
    </Block>
  );
};

const PumpkinBenefit = () => {
  return (
    <Block background={'#fff4cb'}>
      <Block
        Block
        p={[30, 70]}
        maxWidth={['initial', 700, 960, 1200]}
        mx={'auto'}>
        <Text
          textAlign={'center'}
          fontWeight={'bold'}
          fontSize={[30,36]}
          mb={30}
          color={'#884321'}>
          มี PUMPKIN แล้วดียังไง?
        </Text>
        <Grid container justify="center" alignItems="baseline" spacing={24}>
          <Grid item xs={12} sm={4}>
            {BenefitBox(
              'สะสมแต้ม',
              'เพียงแสกน QR Code ที่ร้านค้าก็สะสมแต้มเพื่อแลกรางวัลได้เลย',
              CollectPointBenefit
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            {BenefitBox(
              'แจ้งเตือน',
              'รับข่าวสาร และ โปรโมชั่น ที่จะทำให้คุณไม่พลาดโปรโมชั่นจากร้านค้า',
              NotificationBenefit
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            {BenefitBox(
              'ของรางวัล',
              'รับสิทธิพิเศษ, โปรโมชั่นและของรางวัลมากมายจากร้านโปรดของคุณ',
              RewardBenefit
            )}
          </Grid>
        </Grid>
      </Block>
    </Block>
  );
};

export default PumpkinBenefit;

import CloudApi from 'api/cloudApi';
import nth from 'lodash/nth';
import NOTIFICATION_TYPES from './types';
import { selectData, selectCoupons } from './selectors';

export const markAsRead = notificationId => ({
  type: NOTIFICATION_TYPES.MARK_AS_READ,
  payload: notificationId
});

export const getAnnouncements = () => dispatch => {
  return dispatch({
    type: NOTIFICATION_TYPES.GET_ANNOUNCEMENTS,
    payload: CloudApi.getAnnouncements()
  });
};

export const getCoupons = refresh => (dispatch, getState) => {
  const state = getState();
  const coupons = selectCoupons(state);
  const lastItem = nth(coupons, -1);
  const lastMoment = !refresh && lastItem ? lastItem.createdAt : null;
  return dispatch({
    type: NOTIFICATION_TYPES.GET_COUPONS,
    payload: CloudApi.getCoupons(lastMoment),
    meta: !refresh && lastItem
  });
};

export const getPromotions = () => dispatch => {
  return dispatch({
    type: NOTIFICATION_TYPES.GET_PROMOTIONS,
    payload: CloudApi.getPromotions()
  });
};

export const getSurveys = () => dispatch => {
  return dispatch({
    type: NOTIFICATION_TYPES.GET_SURVEYS,
    payload: CloudApi.getSurveys()
  });
};

export const getBenefits = () => (dispatch, getState) => {
  const state = getState();
  const notifications = selectCoupons(state);
  const lastItem = nth(notifications, -1);
  const lastMoment = lastItem ? lastItem.createdAt : null;
  return dispatch({
    type: NOTIFICATION_TYPES.GET_BENEFITS,
    payload: CloudApi.getBenefits(lastMoment)
  });
};

// this action is concat the new one to the notifications list.
// because of deeplink issues.
export const getNewNotification = id => dispatch => {
  return dispatch({
    type: NOTIFICATION_TYPES.GET_NEW_NOTIFICATION,
    payload: CloudApi.getNewNotification(id)
  });
};

export const getPublicCampaign = (brandId, campaignId) => ({
  type: NOTIFICATION_TYPES.GET_PUBLIC_CAMPAIGN,
  payload: CloudApi.getPublicCampaign(brandId, campaignId)
});

export const refreshAnnouncements = payload => ({
  type: NOTIFICATION_TYPES.REFRESH_ANNOUNCEMENTS,
  payload
});

export const refreshBenefits = payload => ({
  type: NOTIFICATION_TYPES.REFRESH_BENEFITS,
  payload
});

export const getAllNotifications = refresh => (dispatch, getState) => {
  const state = getState();
  const notifications = selectData(state);
  const lastItem = nth(notifications, -1);
  const lastMoment = !refresh && lastItem ? lastItem.createdAt : null;
  return dispatch({
    type: NOTIFICATION_TYPES.GET_ALL_NOTIFICATION,
    payload: CloudApi.getAllNotifications(lastMoment),
    meta: !refresh && lastItem
  });
};

export const refreshAllNotifications = payload => ({
  type: NOTIFICATION_TYPES.REFRESH_ALL_NOTIFICATION,
  payload
});

export const updateSurvey = payload => ({
  type: NOTIFICATION_TYPES.UPDATE_SURVEY,
  payload
});

export const updateSurveyInRedeem = payload => ({
  type: NOTIFICATION_TYPES.UPDATE_SURVEY_IN_REDEEM,
  payload
});

export const insertNewNotification = payload => ({
  type: NOTIFICATION_TYPES.INSERT_NEW_NOTIFICATION,
  payload
});

export const insertNewCoupon = payload => ({
  type: NOTIFICATION_TYPES.INSERT_NEW_COUPON,
  payload
});

import React from 'react';
import styled from '@emotion/styled';
import { ClassNames } from '@emotion/core';
import { Image, Text, Block, Button } from 'components/atoms';

const PrivilegeCard = styled(
  ({
    imagePath,
    logoPath,
    storeTitle,
    storeType,
    privilegeDescription,
    stampQuantity,
    mt,
    className
  }) => (
    <ClassNames>
      {({ cx }) => (
        <Block
          className={cx('privilege-box', className)}
          mt={mt}
          width={['80%', '50%', '30%']}>
          <Block.Flex className={'card'} width={'100%'}>
            <Block width={'100%'}>
              <Image.Dynamic
                src={imagePath}
                objectFit={'cover'}
                height={175}
                width={'100%'}
              />
            </Block>
            <Block
              display={'flex'}
              flexDirection={'row'}
              alignSelf={'flex-start'}>
              <Block className={'logo'} mt={[0, 0, -42]}>
                <Image.Dynamic
                  src={logoPath}
                  objectFit={'contain'}
                  height={[57, 77, 97]}
                  width={[57, 77, 97]}
                  circular
                />
              </Block>
              <Block
                display={'flex'}
                flexDirection={'column'}
                pl={20}
                pt={[10, 15, 0]}
                css={{
                  WebkitLineClamp: '2',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden'
                }}>
                <Text fontWeight={'bold'} fontSize={[22,22,25]} fontFamily={'thai'}>
                  {storeTitle}
                </Text>
                <Text
                  fontFamily={'thai'}
                  fontSize={[19,19,22]}
                  color={'grey'}
                  mt={-10}
                  mb={10}>
                  {storeType}
                </Text>
              </Block>
            </Block>
            <Block className={'text-box'} width={['80%', '75%']} mx={'auto'} />
            <Block.Flex
              alignItems={'center'}
              alignSelf={'flex-start'}
              width={'100%'}
              justifyContent={'center'}
              mt={-20}>
              <Block
                className={'text-box2'}
                width={['30%', '30%', '45%']}
                mr={10}>
                <Block
                  css={{
                    WebkitLineClamp: '2',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden'
                  }}>
                  <Text
                    fontWeight={'bold'}
                    fontSize={[18, 18, 23]}
                    fontFamily={'thai'}
                    letterSpacing={0}>
                    {privilegeDescription}
                  </Text>
                </Block>
                <Text fontFamily={'thai'} fontSize={19} color={'grey'}>
                  {`${stampQuantity} Stamps`}
                </Text>
              </Block>
              <Button
                rounded
                variant={'contained'}
                color={'primary'}
                mt={[-10, 0]}
                style={{
                  background: 'linear-gradient(to bottom, #ffc370, #ffab34)',
                  fontSize: [18, 18, 23],
                  textTransform: 'none'
                }}
                onClick={() => null}>
                Redeem
              </Button>
            </Block.Flex>
          </Block.Flex>
        </Block>
      )}
    </ClassNames>
  )
)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  position: 'relative',
  margin: 15,
  '.card': {
    borderRadius: '5px',
    boxShadow: '0 0 14px 0 rgba(0, 0, 0, 0.1)',
    flexDirection: 'column',
    alignItems: 'center'
  },
  '.number-box': {
    backgroundColor: '#fe9526',
    borderRadius: 50
  },
  '.text-box': {
    zIndex: 1,
    // width: '80%',
    borderBottom: 'solid 2px #e3e3e3',
    wordWrap: 'break-word'
  },
  '.text-box2': {
    backgroundColor: 'white',
    zIndex: 1,
    alignSelf: 'flex-start',
    marginTop: 39,
    marginBottom: 34,
    wordWrap: 'break-word'
  },
  '.logo': {
    background: 'white',
    border: '8px solid white',
    borderRadius: '50%',
    alignSelf: 'flex-start',
    zIndex: 2,
    marginLeft: 26
  }
}));

export default PrivilegeCard;

import withWidth from '@material-ui/core/withWidth/withWidth';
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
// import { FormattedMessage } from 'react-intl';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { Block, Image, Text } from 'components/atoms';
import Done from '@material-ui/icons/Done';

const breakpoints = createBreakpoints({});
const planName = ['Free', 'Starter', 'Standard', 'Pro'];

const Root = styled('div')({
  background: 'white',
  alignItems: 'flex-start',
  '.hideBottomRow': {
    borderBottom: 'hidden'
  },
  '.quota-row': {
    [breakpoints.down('xs')]: {
      borderBottom: 'hidden'
    },
    borderBottom: 'hidden'
  },
  '.quota-last-row': {
    [breakpoints.down('xs')]: {
      borderBottom: 'hidden'
    },
    borderBottom: '0px white solid'
  },
  '.quota-mobile-first-column': {
    display: 'table-row',
    [breakpoints.up('sm')]: {
      display: 'none'
    },
    borderBottom: 'hidden'
  },
  '.quota-text': {
    [breakpoints.down('xs')]: {
      borderRight: '1px solid grey',
      width: '100%'
    }
  },
  '.footer-div': {
    textAlign: 'center',
    width: '100%'
  },
  '.package-button': {
    width: '100%',
    height: 48,
    [breakpoints.up('sm')]: {
      width: 300
    }
  },
  '.textCenter': {
    textAlign: 'center',
    borderLeft: '0.5px #ffbb00 solid',
    borderRight: '0.5px #ffbb00 solid',
    [breakpoints.down('xs')]: {
      borderLeft: 'hidden',
      borderRight: 'hidden'
    }
  },
  '.image-column': {
    paddingRight: 0,
    paddingLeft: 0,
    textAlign: '-webkit-center',
    borderLeft: '0.5px #ffbb00 solid',
    borderRight: '0.5px #ffbb00 solid',
    borderTop: '1px #ffbb00 solid',
    [breakpoints.down('xs')]: {
      borderLeft: 'hidden',
      borderRight: 'hidden'
    }
  },
  '.header-text': {
    fontSize: 19,
    [breakpoints.up('sm')]: {
      fontSize: 16
    }
  },
  '.quotaBox': {
    borderRight: '0.5px #ffbb00 solid',
    borderLeft: '0.5px #ffbb00 solid',
    [breakpoints.down('xs')]: {
      borderRight: 'hidden',
      borderLeft: 'hidden'
    }
  },
  '.lastRowBox': {
    borderRight: '0.5px #ffbb00 solid',
    borderLeft: '0.5px #ffbb00 solid',
    borderBottom: '0.5px #ffbb00 solid',
    background: 'white',
    [breakpoints.down('xs')]: {
      borderRight: 'hidden',
      borderBottom: 'hidden',
      borderLeft: 'hidden',
      background: ' transparent'
    }
  },
  '.headerRowBox': {
    borderRight: '0.5px #ffbb00 solid',
    borderLeft: '0.5px #ffbb00 solid',
    borderTop: '0.5px #ffbb00 solid',
    background: 'white',
    [breakpoints.down('xs')]: {
      borderRight: 'hidden',
      borderTop: 'hidden',
      borderLeft: 'hidden',
      background: ' transparent'
    }
  },
  '.titleBox': {
    borderRight: '0.5px #ffbb00 solid',
    borderLeft: '0.5px #ffbb00 solid'
  },
  '.recommendBox': {
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px'
  }
});

const PricePlanTable = ({
  plans,
  activePackage = plans.find(plan => plan.price === 599).id
}) => {
  const renderQuota = quota => {
    if (quota === 'Community') return 'Community';
    if (quota === 'Direct Message') return 'แชทโดยตรง';
    return quota;
  };

  const priceTab = price => (
    <Block
      pt={['0px', '5px']}
      pb={['0px', '5px']}
      pr={['5px', '7px', 15]}
      pl={['5px', '7px', 15]}
      borderRadius={50}
      height={30}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      background={'linear-gradient(to top, #ff9500, #ffb650)'}>
      <Text color={'white'} fontSize={['10px', '18px', 22]}>
        {price}฿ / เดือน
      </Text>
    </Block>
  );
  const renderProductQuotaRow = (productType, quotaClassName) =>
    plans.map((item, index) => (
      <Block
        width={'25%'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        height={[
          '100%',
          (quotaClassName === 'headerRowBox' ||
            quotaClassName === 'lastRowBox') &&
          index === 2
            ? '150%'
            : '100%'
        ]}
        className={quotaClassName}>
        {productType !== 'customerStatistic' ? (
          <Text
            color={'#8b8b8b'}
            textAlign={'center'}
            fontSize={[15, 22]}
            fontWeight={500}
            className={index !== plans.length - 1 ? 'quota-text' : ''}>
            {item.products[productType].quota !== 0
              ? renderQuota(item.products[productType].quota)
              : '-'}
          </Text>
        ) : (
          <Block className={index !== plans.length - 1 ? 'quota-text' : ''}>
            <Block
              background={'#91dc5a'}
              width={19}
              height={19}
              borderRadius={'50%'}
              m={'auto'}>
              <Done style={{ width: 19, height: 19, color: 'white' }} />
            </Block>
          </Block>
        )}
      </Block>
    ));
  const renderRow = (title, productType, rowPosition) => (
    <Block>
      <Block display={['block', 'none']} mt={16} mb={16}>
        <Text textAlign={'center'}>{title}</Text>
      </Block>
      <Block display={'flex'} width={'100%'}>
        <Block
          width={'25%'}
          display={['none', 'flex']}
          height={35}
          alignItems={'center'}
          fontWeight={450}
          className={setCssQuotaBox(rowPosition)}
          p={15}
          pt={10}
          pb={10}>
          <Text color={'#a9a9a9'}>{title}</Text>
        </Block>
        <Block width={['100%', '75%']} display={'flex'} alignItems={'center'}>
          {renderProductQuotaRow(productType, setCssQuotaBox(rowPosition))}
        </Block>
      </Block>
    </Block>
  );

  const renderPriceRow = () => (
    <Block>
      <Block display={['block', 'none']} mt={16} mb={16} pt={[25, 0]}>
        <Text textAlign={'center'} fontSize={20} fontWeight={'bold'}>
          ฟีเจอร์พื้นฐาน
        </Text>
      </Block>
      <Block display={['none', 'flex']} width={'100%'}>
        <Block
          width={'25%'}
          display={['none', 'flex']}
          height={35}
          alignItems={'center'}
          p={15}
          pt={10}
          pb={10}
          className={'quotaBox'}>
          <Text fontSize={20} fontWeight={'bold'}>
            ฟีเจอร์พื้นฐาน
          </Text>
        </Block>
        <Block width={['100%', '75%']} display={'flex'} alignItems={'center'}>
          {plans.map(item => (
            <Block
              width={'25%'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              height={'100%'}
              className={'quotaBox'}>
              {item.price === 0 ? null : priceTab(item.price)}
            </Block>
          ))}
        </Block>
      </Block>
    </Block>
  );

  const renderPackageImage = quotaClassName =>
    plans.map((item, index) => (
      <Block
        width={'25%'}
        position={'relative'}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        height={[
          '100%',
          (quotaClassName === 'headerRowBox' ||
            quotaClassName === 'lastRowBox') &&
          index === 2
            ? '113%'
            : '100%'
        ]}
        className={quotaClassName}>
        <Image src={item.imageUrl} width={[60, 120]} />
        <Text fontSize={[18, 25]} fontWeight={'bold'}>
          {planName[index]}
        </Text>
        <Block display={['block', 'none']}>
          {item.price !== 0 ? priceTab(item.price) : null}
        </Block>
        {index === 2 ? (
          <Block
            display={['none', 'block']}
            position={'absolute'}
            background={
              'linear-gradient(to bottom, #f5515f, #d75f6d 46%, #9f041b)'
            }
            p={'8px'}
            top={'1px'}
            right={[0, 15]}
            className={'recommendBox'}>
            <Text color={'white'} fontWeight={'bold'} fontSize={18}>
              Best!
            </Text>
          </Block>
        ) : null}
      </Block>
    ));

  const setCssQuotaBox = rowPosition => {
    if (rowPosition === 'startRow') {
      return 'headerRowBox';
    }
    if (rowPosition === 'lastRow') {
      return 'lastRowBox';
    }
    return 'quotaBox';
  };

  const renderHeader = rowPosition => (
    <Block display={'flex'} width={'100%'}>
      <Block
        width={'25%'}
        display={['none', 'flex']}
        height={160}
        className={setCssQuotaBox(rowPosition)}
        p={15}
        alignItems={'flex-end'}>
        <Text fontSize={25} fontWeight={'bold'}>
          Package
        </Text>
      </Block>
      <Block
        width={['100%', '75%']}
        display={'flex'}
        alignItems={['baseline', 'center']}>
        {renderPackageImage(setCssQuotaBox(rowPosition))}
      </Block>
    </Block>
  );

  const renderSection = () => (
    <Block>
      <Block display={['block', 'none']} mt={35}>
        <Text textAlign={'center'} fontSize={20} fontWeight={'bold'}>
          ฟีเจอร์เพิ่มเติม
        </Text>
      </Block>
      <Block display={'flex'} width={'100%'}>
        <Block
          width={'25%'}
          display={['none', 'flex']}
          height={35}
          alignItems={'center'}
          className={'quotaBox'}
          p={15}
          pt={10}
          pb={10}>
          <Text fontSize={20} fontWeight={'bold'}>
            ฟีเจอร์เพิ่มเติม
          </Text>
        </Block>
        <Block width={['75%']} display={['none', 'flex']}>
          {plans.map(() => <Block width={'25%'} className={'quotaBox'} />)}
        </Block>
      </Block>
    </Block>
  );
  return (
    <Root>
      {renderHeader('startRow')}
      {renderPriceRow()}
      {renderRow('การแลกและการสะสมแต้ม ', 'uniqueUser')}
      {renderRow('แดชบอร์ดแสดงสถิติและข้อมูลลูกค้า ', 'customerStatistic')}
      {renderRow('จำนวนสาขา ', 'branch')}
      {renderRow('จำนวนแอดมิน ', 'admin')}
      {renderSection()}
      {renderRow('จำนวนลูกค้าที่ได้รับสิทธิพิเศษ', 'member')}
      {renderRow('จำนวนลูกค้าที่ซื้อแพ็คเกจ/บัตรกำนัล', 'voucher')}
      {renderRow('Notifications ในแอพที่ส่งถึงลูกค้า ', 'directMessage')}
      {renderRow('E-Coupon ที่ส่งถึงลูกค้า ', 'campaign')}
      {renderRow('จำนวนแบบสอบถาม หรือโพล ', 'survey', 'lastRow')}
    </Root>
  );
};

PricePlanTable.propTypes = {
  plans: PropTypes.arrayOf(PropTypes.shape({})),
  width: PropTypes.string.isRequired,
  activePackage: PropTypes.string,
  setActivePackage: PropTypes.func.isRequired,
  showOnLandingPage: PropTypes.bool
};
PricePlanTable.defaultProps = {
  plans: [],
  activePackage: '',
  showOnLandingPage: false
};

export default withWidth()(PricePlanTable);

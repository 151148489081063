import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { AuthConsumer } from 'contexts/authContext';
import AuthContext from 'contexts/authContext';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { Block } from 'components/atoms';

import Menu from '@material-ui/icons/Menu';

import avatar from './avatar.jpeg';

const Header = ({ onClickMenu, history }) => {
  return (
    <AuthConsumer>
      {({ me, profile, isFindingAuth, isLoggingIn }) => {
        const shouldRenderLoginButton = !me && !isFindingAuth && !isLoggingIn;
        const shouldRenderAvatar = !!me;
        return (
          <React.Fragment>
            <AppBar position={'sticky'}>
              <Toolbar>
                <Block.Flex justifyContent={'space-between'} width={'100%'}>
                  <IconButton
                    className={'icon-button--small'}
                    onClick={onClickMenu}>
                    <Menu />
                  </IconButton>
                  {shouldRenderLoginButton && (
                    <Button
                      variant={'contained'}
                      size={'small'}
                      color={'primary'}
                      onClick={() => history.push('/login')}>
                      Login
                    </Button>
                  )}
                  {shouldRenderAvatar && (
                    <IconButton
                      className={'icon-button--small'}
                      onClick={() => history.push('/qr-code')}>
                      <Avatar src={me.photoURL || avatar} />
                    </IconButton>
                  )}
                </Block.Flex>
              </Toolbar>
            </AppBar>
            <div
              className={'header__dummy'}
              style={{ height: 56, flexShrink: 0 }}
            />
          </React.Fragment>
        );
      }}
    </AuthConsumer>
  );
};

// const Header = ({ onClickMenu, history }) => {
//   const { me, profile, isFindingAuth, isLoggingIn } = useContext(AuthContext);
//   console.log(useContext(AuthContext));
//   const shouldRenderLoginButton = !me && !isFindingAuth && !isLoggingIn;
//   const shouldRenderAvatar = !!me;
//   return (
//     <React.Fragment>
//       <AppBar position={'fixed'}>
//         <Toolbar>
//           <Block.Flex justifyContent={'space-between'} width={'100%'}>
//             <IconButton className={'icon-button--small'} onClick={onClickMenu}>
//               <Menu />
//             </IconButton>
//             {shouldRenderLoginButton && (
//               <Button
//                 variant={'contained'}
//                 size={'small'}
//                 color={'primary'}
//                 onClick={() => history.push('/login')}>
//                 Login
//               </Button>
//             )}
//             {shouldRenderAvatar && (
//               <IconButton
//                 className={'icon-button--small'}
//                 onClick={() => history.push('/qr-code')}>
//                 <Avatar src={me.photoURL || avatar} />
//               </IconButton>
//             )}
//           </Block.Flex>
//         </Toolbar>
//       </AppBar>
//       <div className={'header__dummy'} style={{ height: 56, flexShrink: 0 }} />
//     </React.Fragment>
//   );
// };
Header.propTypes = {
  onClickMenu: PropTypes.func
};
Header.defaultProps = {
  onClickMenu: () => {}
};

export default withRouter(Header);

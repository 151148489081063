import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { space, color, opacity } from 'styled-system';
import { ClassNames } from '@emotion/core';

const FontAwesome = styled(({ type, icon, className, ...rest }) => {
  return (
    <ClassNames>
      {({ cx }) => (
        <i
          className={cx(
            'FontAwesome',
            type === 'solid' && 'fas',
            type === 'brand' && 'fab',
            type === 'regular' && 'far',
            type === 'light' && 'fal',
            `fa-${icon}`,
            className
          )}
          {...rest}
        />
      )}
    </ClassNames>
  );
})({
  padding: 4
}, space, color, opacity);

FontAwesome.propTypes = {
  icon: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['solid', 'brand', 'regular', 'light'])
};
FontAwesome.defaultProps = {
  type: 'solid'
};

export default FontAwesome;

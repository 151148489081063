import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Text from 'components/atoms/Text';
import { ClassNames } from '@emotion/core';

const Capsule = styled(
  ({
     className,
     status,
     text
   }) => {
    return (
      <ClassNames>
        {({ cx }) => (
          <label className={cx('capsule', status, className)}>
            <Text
              textAlign={'center'}
              color={'white'}
              fontSize={'10px'}
              letterSpacing={'0.5px'}
            >{text}</Text>
          </label>
        )}
      </ClassNames>
    );
  }
)(({ theme }) => ({
  display: 'inline-block',
  borderRadius: 50,
  padding: '3px 12px',
  backgroundColor: theme.get('colors.grey'),
  '&.success': {
    backgroundColor: theme.get('colors.success')
  },
  '&.danger': {
    backgroundColor: theme.get('colors.danger')
  }
}));

Capsule.propTypes = {
  className: PropTypes.string,
  status: PropTypes.oneOf([undefined, 'success', 'danger']),
  text: PropTypes.string
};
Capsule.defaultProps = {
  className: '',
  status: undefined,
  text: ''
};

export default Capsule;

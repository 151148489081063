import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

const GatsbyImageBackground = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 4160) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.src);
      });
      if (!image) {
        return null;
      }
      const imageFluid = image.node.childImageSharp.fluid;
      return (
        <BackgroundImage
          Tag="section"
          fluid={imageFluid}
          backgroundColor={`#040e18`}
          css={props.css}
          style={{ ...props.backgroundStyle, backgroundColor:'white' }}>
          {props.children}
        </BackgroundImage>
      );
    }}
  />
);

export default GatsbyImageBackground;

const ImageFactory = () => {
  const toBase64 = (file, callback) => {
    const reader = new FileReader()
    reader.onload = e => callback(e.target.result)
    reader.readAsDataURL(file)
  }

  return {
    toBase64
  }
}

export default ImageFactory()

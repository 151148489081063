const STAGE = process.env.STAGE

export default promise => (...args) =>
  promise(...args).catch(err => {
    if (STAGE !== 'production') {
      console.log('%c Error', 'background: #ff0000; color: #ffffff')
      console.log({ err })
    }
    throw err
  })

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

// COMPONENTS
import { Text, Image } from 'components/atoms';
import Processing from './Processing';
import Modal from '../Modal/Modal';

// PICTURE
import successPicture from 'assets/success.png';
import dangerPicture from 'assets/danger.png';

const icons = {
  success: successPicture,
  danger: dangerPicture
};

const StyledModal = styled(Modal)({
  '.box': {
    textAlign: 'center'
  }
});

class Alert extends Component {
  render() {
    const { open, onClose, status, title, description } = this.props;
    return (
      <StyledModal
        open={open}
        onClose={onClose}
        content={
          <div className={'box'}>
            <Image
              src={icons[status]}
              style={{
                margin: 'auto',
                marginBottom: 15,
                width: 70
              }}
            />
            <Text.ThaiHeading>{title}</Text.ThaiHeading>
            <Text fontSize={20} fontWeight={'semiBold'}>
              {description}
            </Text>
          </div>
        }
      />
    );
  }
}

Alert.Processing = Processing;
Alert.NOT_ALLOWED = 'ไม่สามารถทำรายการได้';
Alert.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.oneOf(['success', 'danger'])
};
Alert.defaultProps = {
  title: 'Success',
  description: '',
  status: 'success'
};

export default Alert;

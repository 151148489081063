import React from 'react';
import { Block, Text, Button, Loader } from 'components/atoms';
import PinInput from 'react-pin-input';
import Countdown from 'react-countdown-now';

const OtpForm = ({
  errorMessage,
  sendOtpPending,
  handleOTP,
  handleResend,
  onChange,
  countDownKey,
  sendSmsPending,
  setCountDownKey,
  otp
}) => {
  const countDownRenderer = ({ seconds, completed }) => {
    if (completed) {
      setCountDownKey(null);
      return (
        <Button
          id="resend-button"
          className={'reset-button'}
          onClick={() => handleResend()}
          disabled={sendSmsPending}>
          Resend
        </Button>
      );
    } else {
      return (
        <Text>You'll be able to resend OTP again in {seconds} seconds</Text>
      );
    }
  };

  return (
    <Block
      css={{
        '@media only screen and (max-width: 450px)': {
          '.pincode-input-text': {
            width: '35px !important',
            height: '35px !important'
          }
        }
      }}>
      {sendOtpPending && <Loader />}
      <Text mb={['5px', '10px']}>Verify OTP</Text>
      <Block mb={['5px', '10px']} textAlign={'center'}>
        <PinInput
          length={6}
          focus
          type="numeric"
          onChange={onChange}
          inputFocusStyle={{ border: '1px solid #f89202' }}
          onComplete={value => handleOTP(value)}
        />
      </Block>
      {errorMessage && <Text color="red">{errorMessage}</Text>}
      <Block
        css={{
          '.MuiButton-root': {
            padding: '0px',
            textDecoration: 'underline'
          },
          '.MuiButton-root:hover': {
            backgroundColor: 'transparent'
          }
        }}>
        <Countdown
          key={countDownKey}
          date={countDownKey ? Date.now() + 50000 : 0}
          renderer={countDownRenderer}
        />
      </Block>
    </Block>
  );
};

export default OtpForm;

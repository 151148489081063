import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ClassNames } from '@emotion/core';

// COMPONENTS
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Block, Text } from 'components/atoms';

// ICONS
import Close from '@material-ui/icons/Close';

// REDUX

// HELPERS

const Modal = styled(
  ({
     className,
     open,
     onClose,
     header,
     title,
     content,
     actions,
     fullScreen
   }) => {
    return (
      <ClassNames>
        {({ cx }) => (
          <Dialog
            classes={{ paper: 'paper' }}
            open={open}
            onClose={onClose}
            className={cx('modal', className)}
            fullScreen={fullScreen}>
            {header ? (
              <AppBar className={'app-bar'}>
                <Toolbar>
                  <Text variant="title" color="inherit" className={'app-bar-title'}>
                    {title}
                  </Text>
                  <IconButton onClick={onClose} color="inherit" aria-label="Close">
                    <Close/>
                  </IconButton>
                </Toolbar>
              </AppBar>
            ) : (
              <Block.Flex justifyContent={'space-between'}>
                <Block pl={16} py={16}>
                  <Text.Heading fontSize={4}>{title}</Text.Heading>
                </Block>
                <div className={'close-icon'}>
                  <Close onClick={onClose}/>
                </div>
              </Block.Flex>
            )}
            <DialogContent className={'dialog-content'}>{content}</DialogContent>
            <DialogActions className={'actions'}>
              {React.Children.toArray(actions)}
            </DialogActions>
          </Dialog>
        )}
      </ClassNames>
    );
  }
)(({ theme, minWidth = 300, maxWidth = 1000 }) => ({
  '.app-bar': {
    position: 'relative',
    marginBottom: 16,
    '.app-bar-title': {
      flex: 1
    }
  },
  '.paper': {
    minWidth: minWidth,
    maxWidth: maxWidth
  },
  '.actions': {
    margin: '0 16px 16px',
    '.button:last-child': {
      marginRight: 0
    }
  },
  '.close-icon': {
    padding: '16px 16px 0 0',
    cursor: 'pointer',
    svg: {
      transition: '0.3s'
    }
    // ':hover': {
    //   svg: {
    //     color: theme.get('colors.black')
    //   }
    // }
  }
}));

Modal.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
Modal.defaultProps = {
  open: false,
  loading: false,
  header: false,
  onClose: () => {
  },
  name: ''
};

export default Modal;

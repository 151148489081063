import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ClassNames } from '@emotion/core';
import { Image, Text, Block } from 'components/atoms';
import CardGiftcard from '@material-ui/icons/CardGiftcard';

const RewardCard = styled(({ className, title, rewardPoint, rewardImage }) => {
  return (
    <ClassNames>
      {({ cx }) => (
        <div className={cx('reward-card', className)}>
          <div className={'info'}>
            <Image src={rewardImage} objectFit={'cover'}/>
            <div className={'detail'}>
              <Text fontSize={4} fontWeight={600} lineHeight={1} mb={'4px'}>
                {title}
              </Text>
              <Text fontSize={3}>เมื่อสะสมครบ {rewardPoint} ดวง</Text>
            </div>
          </div>
          <Block.MiddleCenter className={'action'} py={10}>
            <CardGiftcard/>
            <Text color={'white'} fontSize={4} fontWeight={500}>
              แลก
            </Text>
          </Block.MiddleCenter>
        </div>
      )}
    </ClassNames>
  );
})(({ theme, primaryColor }) => ({
  borderRadius: 12,
  overflow: 'hidden',
  boxShadow: theme.get('globalShadows.lightShadow'),
  '.pumpkin-image': {
    height: 85,
    width: 85,
    borderRadius: 6
  },
  '.info': {
    padding: 15,
    display: 'flex',
    '.detail': {
      paddingLeft: 15,
      flex: 1,
      '.pumpkin-text': {
        ...theme.get('thaiFont')
      }
    }
  },
  '.action': {
    backgroundColor: primaryColor,
    '& svg': {
      color: '#ffffff'
    },
    '.pumpkin-text': {
      marginLeft: 10,
      ...theme.get('thaiFont')
    }
  }
}));

RewardCard.propTypes = {
  primaryColor: PropTypes.string
};

export default RewardCard;

import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

export default () => compose(
  WrappedComponent => {
    class DampCallback extends React.Component {

      static propTypes = {
        duration: PropTypes.number,
        callback: PropTypes.func.isRequired,
      }

      static defaultProps = {
        duration: 5,
      }

      constructor(props) {
        super(props)
        this.state = {
          secondLeft: props.duration
        }
      }

      startCountDown = () => {
        this.countDown = setInterval(this.countDown, 1000)
      }

      stopCountDown = () => {
        clearInterval(this.countDown)
      }

      countDown = () => {
        if (this.state.secondLeft - 1 <= 0) {
          return this.setState({ secondLeft: 0 }, () => {
            this.stopCountDown()
            setTimeout(this.props.callback, 17)
          })
        }
        this.setState(({ secondLeft }) => ({ secondLeft: secondLeft - 1 }))
      }

      render() {
        return (
          <WrappedComponent
            {...this.props}
            startCountDown={this.startCountDown}
            secondLeft={this.state.secondLeft}
          />
        )
      }
    }
    return DampCallback
  }
)

import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { Block } from 'components/atoms';
import omit from 'lodash/omit';

const GatsbyImageBackground = props =>
  props.background ? (
    <Block
      backgroundImage={props.backgroundColor}
      backgroundColor={props.backgroundColor || 'white'}
      width={props.width}
      height={props.height}
      m={props.m}>
      <BackgroundImage
        Tag="section"
        fluid={props.background}
        style={{ ...props.backgroundStyle }}>
        <Block
          {...omit(props, [
            'background',
            'backgroundColor',
            'backgroundStyle',
            'width',
            'm'
          ])}
          css={props.css}>
          {props.children}
        </Block>
      </BackgroundImage>
    </Block>
  ) : (
    <Block
      backgroundColor={props.backgroundColor || 'white'}
      {...omit(props, ['background', 'backgroundColor', 'backgroundStyle'])}>
      {props.children}
    </Block>
  );

export default GatsbyImageBackground;

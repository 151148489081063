import React from 'react';
import PropTypes from 'prop-types';
import Block from '../Block';
import Text from '../Text';
import StatisticGroup from './StatisticGroup';
import { ClassNames } from '@emotion/core';

const Statistic = ({ label, value, className }) => {
  return (
    <ClassNames>
      {({ cx }) => (
        <Block className={cx('statistic', className)} flexDirection={'column'}>
          <Text color={'grey'} className={'label'}>
            {label}
          </Text>
          <Text
            className={'amount'}
            fontWeight={'extraLight'}
            fontSize={5}
            textAlign={'left'}>
            {value}
          </Text>
        </Block>
      )}
    </ClassNames>
  );
};

Statistic.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
Statistic.defaultProps = {};

Statistic.Group = StatisticGroup;
Statistic.Group.propTypes = {
  statistics: PropTypes.arrayOf(PropTypes.shape(Statistic.propTypes))
};

export default Statistic;

import React from 'react';
import { BrandsSlider } from 'components/molecules';
import { Block, Text, Button } from 'components/atoms';
import { navigate } from 'gatsby';

// REDUX
import { connect } from 'react-redux';
import {
  selectNewbrandsStatus,
  selectNewbrands
} from 'modules/newbrand/selectors';
import { getNewBrands } from 'modules/newbrand/actions';
import { bindActionCreators } from 'redux';

class ParticipatingStores extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: `Beverage`,
      cafe: []
    };
  }

  componentDidMount() {
    const { newBrands } = this.props;

    if (!newBrands || !newBrands.length) {
      this.props.getNewBrands();
    }
  }
  selectCategory = category => {
    this.setState({ formState: category });
  };

  renderTextButton = category => {
    return (
      <Block.Middle
        height={'100%'}
        css={{
          cursor: 'pointer',
          ':hover > *': {
            color: '#f89202 !important',
            textDecoration: 'underline'
          }
        }}
        justifyContent={'center'}
        onClick={() => this.selectCategory(category)}
        pt={[12, 0, 0]}
        pb={[12, 0, 0]}>
        <Text
          color={
            this.state.formState === category ? '#f89202 !important' : 'grey'
          }
          style={{
            textDecoration: this.state.formState === category && 'underline'
          }}
          mx={[12, 12, 16, 24]}
          fontSize={[18, 20, 22]}
          fontWeight={500}>
          {category}
        </Text>
      </Block.Middle>
    );
  };

  renderBrandList = () => {
    const { formState } = this.state;
    const { newBrands } = this.props;
    const items =
      newBrands.length === 0
        ? []
        : newBrands
            .filter(({ category = { name: 'Others' } }) => {
              return category && category.name === formState;
            })
            .map(({ logo }) => logo);

    return <BrandsSlider category={items} />;
  };

  renderExploreButton = () => (
    <Button
      rounded
      variant={'contained'}
      color={'primary'}
      style={{
        background: 'linear-gradient(to bottom, #ffc370, #ffab34)',
        fontSize: 16,
        textTransform: 'none'
      }}
      onClick={() => navigate('/shop-category/')}>
      Explore
    </Button>
  );

  render() {
    const { newBrands } = this.props;

    return (
      <Block maxWidth={['initial', 700, 960, 1200]} mx={'auto'} mb={170}>
        <Block
          display={'flex'}
          flexDirection="row"
          flexWrap="wrap"
          mt={40}
          justifyContent={'space-between'}
          pl={[4, 0]}>
          <Text color="Black" fontWeight="bold">
            ร้านค้าที่ร่วมรายการ
          </Text>
          <Block display={['flex', 'none']} pr={4}>
            {this.renderExploreButton()}
          </Block>
          <Block display={['none', 'flex']}>
            {this.renderTextButton('Beverage')}
            {this.renderTextButton(`Cafe`)}
            {this.renderTextButton('Others')}
            {this.renderExploreButton()}
          </Block>
        </Block>
        <Block display={['flex', 'none']} justifyContent={'center'}>
          {this.renderTextButton('Beverage')}
          {this.renderTextButton(`Cafe`)}
          {this.renderTextButton('Others')}
        </Block>
        <Block>{newBrands.length > 0 && this.renderBrandList()}</Block>
      </Block>
    );
  }
}

export default connect(
  state => ({
    getNewbrandsStatus: selectNewbrandsStatus(state),
    newBrands: selectNewbrands(state)
  }),
  dispatch =>
    bindActionCreators(
      {
        getNewBrands
      },
      dispatch
    )
)(ParticipatingStores);

// export default ParticipatingStores

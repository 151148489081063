import React from 'react'
import Statistic from './Statistic'
import styled from '@emotion/styled'
import  Block  from '../Block'

const StatisticGroup = ({ statistics, children, classes , className}) => (
  <Block display={'flex'} className={className?className: classes.group}>
    {children || (
      statistics.map((stat, index) => (
        <Statistic
          key={index} {...stat}
        />
      ))
    )}
  </Block>
)

const spaces = {
  small: 25,
  medium: 70,
  large: 100,
}

const GroupStatistic = styled(StatisticGroup)(
  ({ space = 'medium' }) => ({
    '& .statistic:not(:first-child)': {
      marginLeft: spaces[space],
    }
  })
)

export default GroupStatistic

import React from 'react';
import throttle from 'lodash/throttle';
import Hidden from '@material-ui/core/Hidden';
import { Block, Image, Text, GatsbyImage } from 'components/atoms';
import { Link, navigate } from 'gatsby';
import { Navigator } from 'components/organisms';
import sideBar from './sideBar';
import Profile from './Profile';
import { auth } from 'appFirebase/config';
import get from 'lodash/get';
import logo from 'assets/logo.svg';

const menu = 'assets/menu.png';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.handleScroll = throttle(this.handleScroll.bind(this), 200);
    this.state = {
      headerBackground: {},
      showMobileMenu: false,
      formState: '',
      sideBarLists: []
    };
  }

  componentDidMount() {
    this.setState({ sideBarLists: sideBar });
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    if (window.scrollY > 50) {
      return this.setState({
        headerBackground: {
          boxShadow: '0px 2px 10px 0px rgba(192,192,192,0.8)'
        }
      });
    }
    return this.setState({
      headerBackground: {}
    });
  }

  renderLink = (text, route) => {
    if (typeof window !== 'undefined') {
      const pathArray = window.location.pathname.split('/');
      if (pathArray[1] === route.split('/')[1])
        return this.renderActiveLink(text);
    }
    return this.renderNonActiveLink(text, route);
  };

  renderNonActiveLink = (text, route) => (
    <Link to={route} style={{ textDecoration: 'none' }}>
      <Block.Middle
        height={'100%'}
        css={{
          cursor: 'pointer',
          ':hover > *': { color: '#f89202 !important' }
        }}
        justifyContent={'center'}
        onClick={() => {
          if (this.state.showMobileMenu)
            return this.setState({ showMobileMenu: false });
        }}
        pt={[12, 0, 0]}
        pb={[12, 0, 0]}>
        <Text
          mx={[12, 12, 16, 24]}
          component={'a'}
          fontSize={[16, 18, 20]}
          fontWeight={500}>
          {text}
        </Text>
      </Block.Middle>
    </Link>
  );

  renderActiveLink = text => (
    <Block.Middle
      height={'100%'}
      css={{
        cursor: 'pointer',
        ':hover > *': { color: '#f89202 !important' }
      }}
      background={['none', '#fafafa']}
      borderBottom={
        this.state.showMobileMenu ? 'none' : ['none', '#f89202 3px solid']
      }
      color={'red'}
      justifyContent={'center'}
      onClick={() => {
        if (this.state.showMobileMenu)
          return this.setState({ showMobileMenu: false });
      }}
      pt={[12, 0, 0]}
      pb={[12, 0, 0]}>
      <Text
        mx={[12, 12, 16, 24]}
        component={'a'}
        fontSize={[16, 18, 20]}
        fontWeight={500}>
        {text}
      </Text>
    </Block.Middle>
  );

  render() {
    const { showMobileMenu, sideBarLists } = this.state;
    const me = get(auth, 'currentUser', null);
    return (
      <Block>
        <Block
          position={'sticky'}
          top={0}
          style={{
            transition: '0.3s',
            zIndex: 1000,
            boxShadow: '0px 2px 10px 0px rgba(192,192,192,0.8)',
            background: 'white',
            top: 0,
            width: '100%'
          }}>
          <Block.Middle
            px={[16, 0]}
            minHeight={[64, 80]}
            justifyContent={'space-between'}
            maxWidth={['initial', 700, 960, 1200]}
            mx={'auto'}>
            <GatsbyImage
              src={menu}
              display={['flex', 'none', 'none']}
              width={30}
              css={{ cursor: 'pointer' }}
              onClick={() => this.setState({ showMobileMenu: !showMobileMenu })}
            />
            <Image display={['none', 'flex']} mt={'4px'} src={logo} />
            <Hidden xsDown>
              <Block.Middle alignSelf={'stretch'}>
                {sideBar.map(item => this.renderLink(item.label, item.path))}
                <Block ml={[12, 12, 16, 24]}>
                  <Profile />
                </Block>
              </Block.Middle>
            </Hidden>
            <Hidden smUp>
              <Profile />
            </Hidden>
          </Block.Middle>
        </Block>
        <Block
          style={{ zIndex: 10000 }}
          position={'absolute'}
          display={showMobileMenu ? 'flex' : 'none'}>
          <Navigator
            items={sideBarLists.map(item => ({
              ...item,
              active:
                typeof window !== 'undefined'
                  ? item.path === window.location.pathname
                  : false,
              toPath: () => navigate(item.path)
            }))}
            open={showMobileMenu}
            onClose={() => this.setState({ showMobileMenu: false })}
            me={me}
          />
        </Block>
      </Block>
    );
  }
}

export default Header;

import isArray from 'lodash/isArray'
import isBoolean from 'lodash/isBoolean'
import isNumber from 'lodash/isNumber'

export const withQueries = queries => ref => {
  let newRef = ref
  queries.forEach(query => {
    if (isBoolean(query[2])) {
      newRef = newRef.where(...query)
    } else if (query && query.length === 3 && !!query[2]) {
      // query length must follow firebase pattern
      // https://firebase.google.com/docs/firestore/query-data/queries
      newRef = newRef.where(...query)
    }
  })
  return newRef
}
export const withOrderBy = orderBy => ref => {
  if (!orderBy || !isArray(orderBy) || orderBy.length === 0) {
    return ref
  }
  let newRef = ref
  if (isArray(orderBy[0])) {
    orderBy.forEach(order => {
      newRef = newRef.orderBy(...order)
    })
    return newRef
  }
  return newRef.orderBy(...orderBy)
}
export const withLimit = limit => ref => {
  if (!limit || !isNumber(limit)) {
    return ref
  }
  return ref.limit(limit)
}
export const withCursor = (cursorMethod, cursorValue) => ref => {
  if (!cursorMethod || !cursorValue) {
    return ref
  }
  if (isArray(cursorValue)) {
    return ref[cursorMethod](...cursorValue)
  }
  return ref[cursorMethod](cursorValue)
}

import React from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import styled from '@emotion/styled';
import { reduceStyles } from 'helpers/functions';
import ProgressiveImage from './ProgressiveImage';
import { ClassNames } from '@emotion/core';

const name = 'pumpkin-image';

const StyledImage = styled('img')(
  {
    display: 'block',
    maxWidth: '100%',
    transition: '0.4s',
    '&.image--circular': {
      borderRadius: '50%'
    },
    '&.image--rounded': {
      borderRadius: 4
    }
  },
  ({ loading, objectFit, css, theme }) => ({
    opacity: loading ? 0 : 1,
    objectFit: objectFit || 'cover',
    ...isFunction(css) ? css(theme) : css
  }),
  ...reduceStyles(`&.${name}`)
);

const Image = ({ src, className, circular, rounded, objectFit, ...props }) => {
  return (
    <ProgressiveImage src={src}>
      {(imageSrc, loading) => {
        return (
          <ClassNames>
            {({ cx }) => (
              <StyledImage
                className={cx(
                  name,
                  className,
                  circular && 'image--circular',
                  rounded && 'image--rounded'
                )}
                src={imageSrc}
                objectFit={objectFit}
                {...props}
                loading={loading}
              />
            )}
          </ClassNames>
        );
      }}
    </ProgressiveImage>
  );
};

Image.propTypes = {
  src: PropTypes.string,
  objectFit: PropTypes.string,
  className: PropTypes.string
};

export default Image;

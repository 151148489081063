import React, { useEffect } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import logo from 'assets/pumpkin-head.png';
import logo2 from 'assets/muze-logo.png';
import { Block, Image, Text } from 'components/atoms';
import { css, jsx } from '@emotion/core';

// REDUX
import { connect } from 'react-redux';
import {
  selectGetRewardsStatus,
  selectRewards
} from 'modules/reward/selectors';
import { getRewards } from 'modules/reward/actions';
import { bindActionCreators } from 'redux';

const MyCardRewardSection = ({
  className,
  brandId,
  rewards,
  getRewards,
  color
}) => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStep = step => () => {
    setActiveStep(step);
  };

  useEffect(() => {
    if (!rewards || !rewards.length) {
      getRewards(brandId);
    }
  }, []);

  const overRide = css`
    .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
      margin-top: 0;
    }

    ,
    .MuiStepLabel-label.MuiStepLabel-active .pumpkin-text {
      color: black;
    }

    ,
    .MuiStepConnector-alternativeLabel {
      top: 28%;
    }

    ,
    .MuiStepper-root {
      padding: 0;
      padding-top: 24;
      padding-bottom: 24;
    }

    ,
    .MuiStepConnector-lineHorizontal {
      border-color: ${color};
    }
  `;

  return (
    <Block>
      {rewards[brandId] && (
        <Block css={[overRide]}>
          <Stepper alternativeLabel nonLinear activeStep={activeStep}>
            {rewards[brandId].map(({ id, point, image }, index) => {
              const stepProps = {};
              const buttonProps = {};
              return (
                <Step key={id} {...stepProps}>
                  <StepButton
                    onClick={handleStep(index)}
                    {...buttonProps}
                    icon={
                      <Block
                        background={'white'}
                        border={`2px solid ${color}`}
                        borderRadius={'50%'}>
                        <Image
                          src={image}
                          objectFit={'contain'}
                          height={activeStep === index ? 37 : 30}
                          width={activeStep === index ? 37 : 30}
                          circular
                        />
                      </Block>
                    }>
                    <Text fontSize={19} color={'grey'}>
                      {`${point} Points`}
                    </Text>
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
          <Block.Flex width={'100%'}>
            <Block alignSelf={'center'} maxWidth={117} minWidth={95}>
              <Image
                src={rewards[brandId][activeStep].image}
                borderRadius={8}
              />
            </Block>
            <Block.Flex
              flexDirection={'column'}
              ml={31}
              style={{ wordWrap: 'break-word' }}
              width={300}>
              <Text fontWeight={'bold'} fontSize={32}>
                {rewards[brandId][activeStep].title}
              </Text>
              <Text fontSize={25} color={'grey'}>
                {rewards[brandId][activeStep].description}
              </Text>
            </Block.Flex>
          </Block.Flex>
        </Block>
      )}
    </Block>
  );
};

export default connect(
  state => ({
    getRewardsStatus: selectGetRewardsStatus(state),
    rewards: selectRewards(state)
  }),
  dispatch =>
    bindActionCreators(
      {
        getRewards
      },
      dispatch
    )
)(MyCardRewardSection);

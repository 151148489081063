import React from 'react';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import {
  color,
  fontWeight,
  fontSize,
  letterSpacing,
  textAlign,
  space,
  fontFamily,
  lineHeight,
  opacity
} from 'styled-system';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';
import ThaiHeading from './ThaiHeading';
import ThaiContent from './ThaiContent';
import Heading from './Heading';
import { ClassNames } from '@emotion/core';

const Text = styled(({ className, inline, ...props }) => (
  <ClassNames>
    {({ cx }) => (
      <Typography
        className={cx('pumpkin-text', className, inline && 'inline')}
        {...omit(props, ['color', 'textAlign', 'danger', 'lineHeight', 'opacity'])}
      />
    )}
  </ClassNames>
))`
  &.pumpkin-text.inline {
    display: inline-block;
  }
  &.pumpkin-text {
    letter-spacing: 1.25px;
    ${fontFamily}
    ${color}
    ${fontWeight}
    ${fontSize}
    ${letterSpacing}
    ${textAlign}
    ${space}
    ${lineHeight}
    ${opacity}
    ${({ danger, theme }) => ({
  ...(danger && {
    color: theme.get('colors.danger')
  })
})}
    
  }
`;

Text.propTypes = {
  color: PropTypes.oneOfType([
    PropTypes.oneOf([
      'red',
      'white',
      'extraLightGrey',
      'lightGrey',
      'grey',
      'darkGrey',
      'black',
      'primary',
      'secondary',
      'inherit',
      '#FFFFFF'
    ]),
    PropTypes.string
  ]),
  fontFamily: PropTypes.oneOf(['thai']),
  fontWeight: PropTypes.oneOfType([
    PropTypes.oneOf([
      'ultraLight',
      'extraLight',
      'light',
      'regular',
      'semiBold',
      'bold',
      'extraBold',
      'ultraBold',
      'black'
    ]),
    PropTypes.number
  ]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  letterSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  danger: PropTypes.bool
};
Text.defaultProps = {
  color: 'black',
  danger: false
};
Text.ThaiHeading = ThaiHeading;
Text.ThaiContent = ThaiContent;
Text.Heading = Heading;

export default Text;
